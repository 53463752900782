import React from 'react';

import axios from 'axios';

import { Link } from 'react-router-dom';

import ContentBlockList from './ContentBlockList';
import License from './License';

const buttonMenuStyle = {
  backgroundImage: 'url("/img/menu-button.png")'
}

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      data: { list: [], detailType: 'License', title: 'Licenses' },
    };
    this.getDetailType = this.getDetailType.bind(this);
    this.detailType = this.detailType.bind(this);
    this.getUrl = this.getUrl.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.loadLicensesFromServer = this.loadLicensesFromServer.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  getDetailType() {
    if (typeof this.props.match.params.detailName == 'undefined') return 'License';
    switch (this.props.match.params.detailName) {
      case 'Users': return 'User';
      case 'Licenses': return 'License';
      case 'ManageUsers': return 'ManageUser';
      case 'Products': return 'Product';
      case 'PasswordSettings': return 'PasswordSetting';
      default: return this.props.match.params.detailName;
    }
  }

  getUrl(detailType) {
    switch (detailType) {
      case 'User': return 'Users';
      case 'License': return 'Licenses';
      case 'PasswordSetting': return 'PasswordSettings';
      default: return detailType;
    }
  }

  getTitle(detailType) {
    switch (detailType) {
      case 'User': return 'Users';
      case 'MyAccount': return 'My Profile';
      case 'License': return 'Licenses';
      case 'ManageUser': return 'Manage Users';
      case 'Product': return 'Products';
      case 'PasswordSetting': return 'Password Setting';
      default: return '';
    }
  }

  toggleNav() {
    this.setState({
      expand: !this.state.expand
    });
    document.body.classList.toggle('push__left');
  }

  loadLicensesFromServer() {
    let detailType = this.getDetailType();
    const self = this;
    return axios.get('/api/Licenses', { params: { version: Math.random() } })
      .then(function (response) {
        if (response) {
          self.setState({
            data:
            {
              userName: self.props.userName,
              isAdmin: self.props.isAdmin,
              list: response.data,
              detailType: detailType,
              title: self.getTitle(detailType),
              reactClass: detailType === 'License' ? License : null
            }
          });
        }

      })
      .catch(function (e) {
        console.error(self.props.match.url, e.status, e.toString());

      });
  }

  componentDidMount() {
    if (!this.props.isAdmin) {
      this.loadLicensesFromServer();
    } else {
      let detailType = this.getDetailType();
      this.setState({
        data:
        {
          userName: this.props.userName,
          isAdmin: this.props.isAdmin,
          list: [],
          detailType: detailType,
          title: this.getTitle(detailType),
          reactClass: true
        }
      });
    }
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (event.target.tagName.toLowerCase() !== 'nav' && event.target.tagName.toLowerCase() !== 'svg' && event.target.tagName.toLowerCase() !== 'use' && document.body.classList.contains('push__left')) {
      this.setState({
        expand: !this.state.expand
      });
      document.body.classList.toggle('push__left');
    }
  }

  handleLogout(e) {
    const self = this;
    this.toggleNav();
    e.preventDefault();
    return axios.post('/api/Logout')
      .then(function () {
        self.props.onUserChange();
        document.cookie = '';
        self.props.history.push('/');
      }).
      catch(function () {
        console.error(e);
      })
  }
  handleClick() {
    this.toggleNav();
    this.moveToDetail('License');
  }

  detailType = function (type) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        detailType: type,
        title: this.getTitle(type)
      }
    }));
  }

  moveToDetail(detail) {
    this.detailType(detail);
    this.props.match.params.detailName = detail;
  }

  render() {
    const navClass = this.state.expand ? 'nav is__visible' : 'nav';
    const contentStyle = this.state.data.detailType === 'License' ? { width: "100%", marginLeft: "0px", marginRight: "0px", maxWidth: "none" } : {};
    const whiteSectionStyle = this.state.data.detailType === 'License' ? { width: "99%", maxWidth: "none" } : {};
    return (
      <div className="grid" style={contentStyle}>
        <div className="grid">
          <header className="grid__col--12 panel--padded--centered" role="banner">
            <a className="site-logo left" href="http://www.informationmapping.com/">
              <b className="srt">Information Mapping Logo</b>
            </a>
            <div className="menu">
              <svg className="menu__icon" onClick={this.toggleNav} style={buttonMenuStyle}>
              </svg>
            </div>
            <h1 className="headline-primary--grouped right site-logo-trial"><a className="site-logo-trial" href="/">FS Pro Console</a></h1>
          </header>
          <nav className={navClass}>
            <ul className="nav__container">
              <li className={this.state.data.detailType === 'License' ? 'nav__item active' : 'nav__item'}><Link to='/Licenses' onClick={this.handleClick}>LICENSES</Link></li>
              <li className="nav__item"><Link to='/user/my-account' onClick={this.handleClick}>MY PROFILE</Link></li>
              {this.props.isAdmin ? (<li className="nav__item"><Link to='/user/list' onClick={this.handleClick}>USERS</Link></li>) : null}
              {this.props.isAdmin ? (<li className="nav__item"><Link to='/product' onClick={this.handleClick}>PRODUCTS</Link></li>) : null}
              {/*this.props.isAdmin ? (<li className="nav__item"><Link to='/single-sign-on' onClick={this.handleClick}>SINGLE SIGN ON</Link></li>) : null*/}
              <li className="nav__item"><a className="nav__link" href="http://support.informationmapping.com/" target="_blank" >SUPPORT &amp; FAQ</a></li>
              {/*<li className="nav__item"><button className="nav__link--button button ui fluid grey" onClick={this.handleLogout} >LOG OUT</button></li>*/}
              <li className="nav__item"><Link to="#" onClick={this.handleLogout} >LOG OUT</Link></li>
            </ul>
          </nav>
        </div>
        <div className="grid">
          <div className="centered grid__col--8">
            <h3 style={{ marginBottom: "8px", color: "white" }} className="headline-secondary--centered">Welcome to the FS Pro Console. Here you can manage your licenses.</h3>

          </div>
        </div>
        <div className="grid" style={whiteSectionStyle}>
          <ContentBlockList data={this.state.data} detailType={this.detailType} onUserChange={this.props.onUserChange} isManager={this.props.isManager}
            changeDetail={this.moveToDetail} onNavigationEvent={this.handleClickOutside} />
        </div>
      </div>
    );
  }
}

export default Application;
