import React, { Component } from 'react';

import '../scss/app.scss';
import '../scss/semantic-ui-css-personalized.scss';

import ModalComponent from './Components/ModalComponent';
import PrivateRoute from './Components/PrivateRoute';
import AuthenticationInformation from './Utilities/AuthenticationInformation';
import { AuthenticationService } from './Utilities/AuthenticationService';
import { Switch, Redirect, withRouter, Route } from 'react-router-dom';
import applicationActionCreator from '../redux/actions/applicationActions';
import { connect } from 'react-redux';
export let goToPageNavigation;
export let onUserChanged;
class Index extends Component {
  constructor(props) {
    super(props);
    const { pathname } = this.props.location
    this.state = {
      loadingState: true,
      previousUrl: pathname,
      userInformation: null
    };
    this.onUserChanged(false);
    goToPageNavigation = this.goToPage.bind(this);
  }

  onToggleLoadingPage = () => {
    this.setState({ loadingState: !this.state.loadingState });
  }

  onUserChanged = function (force = false) {
    const userInformation = null;
    const usr = AuthenticationInformation.instance.userInformation;
    this.props.showLoading('LOADING_USER');
    const self = this;
    return new Promise((resolve) => {
      resolve();
    }).then(function () {
      if (!userInformation || force) {
        self.setState({ userInformation: AuthenticationInformation.instance.userInformation });
        return new AuthenticationService().getCurrentUser();
      }
      else
        AuthenticationInformation.instance.userInformation = userInformation;

    })
      .then(function () {
        if (force)
          self.forceUpdate();
        const { previousUrl } = self.state;
        if (usr !== AuthenticationInformation.instance.userInformation) {
          if (!!AuthenticationInformation.instance.userInformation.name)
            self.goToPage(previousUrl === "/" ? "/private" : previousUrl);
          else
            self.goToPage('/private');
        }
        self.props.hideLoading('LOADING_USER');
      })
  }

  goToPage = (url) => {
    this.setState({ userInformation: AuthenticationInformation.instance.userInformation });
    this.props.history.push(url);
  }

  saveStateGeneral = (value) => {
    this.setState({ ...value });
  }
  componentWillMount = () => {

  }
  componentDidMount() {

  }

  render() {
    const { loadingState, userInformation } = this.state;
    return (
      <div>
        <ModalComponent loadingState={loadingState} />
        {userInformation ? <Switch>
          <PrivateRoute path="/license-usage/:licenseId" goToPage={this.goToPage} saveStateGeneral={this.saveStateGeneral}
            userInformation={this.state.userInformation} onUserChanged={this.props.onUserChange}
            onToggleLoadingPage={this.onToggleLoadingPage} />
          <PrivateRoute path="/license" goToPage={this.goToPage} saveStateGeneral={this.saveStateGeneral}
            userInformation={this.state.userInformation} onUserChanged={this.onUserChanged}
            onToggleLoadingPage={this.onToggleLoadingPage} />
          <PrivateRoute path="/ResetPassword" goToPage={this.goToPage} saveStateGeneral={this.saveStateGeneral}
            userInformation={this.state.userInformation} onUserChanged={this.onUserChanged}
            onToggleLoadingPage={this.onToggleLoadingPage} />
          <PrivateRoute path="/single-sign-on" goToPage={this.goToPage} saveStateGeneral={this.saveStateGeneral}
            userInformation={this.state.userInformation} onUserChanged={this.onUserChanged}
            onToggleLoadingPage={this.onToggleLoadingPage} />
          <PrivateRoute path="/user" goToPage={this.goToPage} saveStateGeneral={this.saveStateGeneral}
            userInformation={this.state.userInformation} onUserChanged={this.onUserChanged}
            onToggleLoadingPage={this.onToggleLoadingPage} />
          <PrivateRoute path="/product" goToPage={this.goToPage} saveStateGeneral={this.saveStateGeneral}
            userInformation={this.state.userInformation} onUserChanged={this.onUserChanged}
            onToggleLoadingPage={this.onToggleLoadingPage} />
          <Redirect exact path="/not-authenticated" to="/not-authenticated" />
          <Route exact path="/" render={props => (
            <Redirect to={`/private`} />
          )} />
          <Redirect path="/*" to="/not-found" />
        </Switch> : null}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
      showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
      hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Index))

