import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom';

import PropsRoute from '../Components/PropsRoute';

import Edit from './Edit';
import List from './List';

export default class Index extends Component {
    render() {
        return (<div>
            <Switch>
                <PropsRoute exact path="/single-sign-on/edit/:siteId" Component={Edit} isEdit={true} />
                <Route exact path="/single-sign-on/new" component={Edit} />
                <Route exact path="/single-sign-on" component={List} />
            </Switch>
            </div>
        )
    }
}
