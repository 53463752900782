import React from 'react';
import Axios from 'axios';

import _ from "underscore";

import { Confirm, Button, Grid, Icon, Checkbox, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import IMComponent from '../Components/IMComponent';
import { goToPageNavigation } from '../Index';
import { getDate } from '../../components/utility/Helper';

import applicationActionCreator from '../../redux/actions/applicationActions';
import { connect } from 'react-redux';

export class LicenseGridForMerging extends IMComponent {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.location.state,
            filter: { email: "" },
            orderBy: [],
            selectedMainLicensePack: "",
            selectedMainLicensePackProductId: "",
            selectedMainValidUntil: "",
            selectedMainUserEmail: "",
            selectedForMerging: [
                {
                    encryptedId: "",
                    endDate: NaN,
                    productId: NaN
                }],

            showConfirmModalForEndDate: false,
            showConfirmModalForProductId: false,
            showCombinedConfirmModal: false
        };

        this.changeEmailOrderBy = this.changeEmailOrderBy.bind(this);
        this.selectMainLicensePack = this.selectMainLicensePack.bind(this);
        this.selectForMergingLicensePack = this.selectForMergingLicensePack.bind(this);
        this.mergeLicenses = this.mergeLicenses.bind(this);
    }

    getProductName = (productId) => {
        switch (productId) {
            case 1: return 'FS Pro Standard';
            case 2: return 'FS Pro for Outlook';
            case 3: return 'FS Pro Business';
            default: return '';
        }
    }

    changeEmailOrderBy = function () {
        const orderBy = this.state.orderBy.filter(function (ordering) { return ordering.name !== "email"; });
        const order = this.state.orderBy.find(function (ordering) { return ordering.name === "email"; });
        this.setState({
            orderBy: [
                ...orderBy,
                {
                    name: "email",
                    dir: order && order.dir === "asc" ? "desc" : "asc"
                }
            ]
        },
            this.callParentFilterChange);
    }

    selectMainLicensePack = (encryptedId, productId, userEmail, validUntil, e) => {
        this.setState((prevState) => {
            let mainLicensePack, mainLicensePackProductId, mainUserEmail, mainValidUntil;

            if (prevState.selectedMainLicensePack === encryptedId) {
                mainLicensePack = mainLicensePackProductId = mainUserEmail = mainValidUntil = '';
            } else {
                mainLicensePack = encryptedId;
                mainLicensePackProductId = productId;
                mainUserEmail = userEmail;
                mainValidUntil = validUntil;
            }

            return {
                selectedMainLicensePack: mainLicensePack,
                selectedMainLicensePackProductId: mainLicensePackProductId,
                selectedForMerging: [],
                selectedMainUserEmail: mainUserEmail,
                selectedMainValidUntil: mainValidUntil,
                selectedMainLicensePacksValidUntil: []
            };
        });
    }

    selectForMergingLicensePack = (encryptedId, endDate, productId, e) => {
        this.setState(prevState => {
            const val = prevState.selectedForMerging.some(x => x.encryptedId === encryptedId)
                ? prevState.selectedForMerging.filter(x => x.encryptedId !== encryptedId)
                : prevState.selectedForMerging.concat({ encryptedId: encryptedId, endDate: endDate, productId: productId });

            return {
                selectedForMerging: val
            };
        });
    }

    mergeLicenses = () => {
        const selectedForMerging = this.state.selectedForMerging.filter(x => x.encryptedId !== "");
        const selectedMainValidUntilTime = getDate(this.state.selectedMainValidUntil, true).getTime();

        // Check EndDate for Merged and Main LicensePacks
        // Should be EndDate for Main longer than Merged License Packs. Otherwise, need to show the following notification
        const showConfirmModalForEndDate = selectedForMerging.some(x => getDate(x.endDate, true).getTime() > selectedMainValidUntilTime);

        // Check that all Merged License Packs have the same ProductId as ProductId for Main License Pack
        // Should be the same ProductIds. Otherwise, need to show the following notification
        const showConfirmModalForProductId = selectedForMerging.some(x => x.productId !== this.state.selectedMainLicensePackProductId);

        if (showConfirmModalForEndDate && showConfirmModalForProductId) {
            this.setState({
                showCombinedConfirmModal: true
            });
        } else {
            if (showConfirmModalForEndDate) {
                this.setState({
                    showConfirmModalForEndDate: showConfirmModalForEndDate
                });
            } else if (showConfirmModalForProductId) {
                this.setState({
                    showConfirmModalForProductId: showConfirmModalForProductId
                });
            }
        }

        if (!showConfirmModalForEndDate && !showConfirmModalForProductId) {
            this.mergeLicensesInternal();
        }
    }

    mergeLicensesInternal = () => {
        const self = this;
        const title = 'License Merge';
        const promise = Axios.get('/api/Licenses/encrypted/' + self.state.selectedMainLicensePack)
            .then(function (response) {
                self.setState({
                    model: {
                        email: response.data.manager,
                        encryptedProductId: response.data.encryptedProductId
                    }
                });
                self.setState({ errors: {} });
            })
            .catch(function (ex) {
                throw ex;
            })
            .then(function () {
                Axios.post(`/api/Licenses/${self.state.selectedMainLicensePack}/LicenseUsages/Merge`,
                    {
                        EncryptedUsageIds: self.state.selectedForMerging.filter(x => x.encryptedId !== "")
                            .map(x => x.encryptedId),
                        EncryptedProductId: self.state.model.encryptedProductId
                    })
                    .then(function () {
                        self.showToastSuccess({ title: title, message: "Licenses were merged successfully." });
                        goToPageNavigation("/");
                    })
                    .catch(function (ex) {
                        if (!!ex.response && ex.response.status === 400) {
                            self.showToastError({ title: title, message: ex.response.data });
                        } else {
                            self.showToastError({
                                title: title,
                                message: "There were some error please contact to Administrators."
                            });
                        }
                    })
                    .finally(function () {
                        self.props.hideLoading("LOAD_SEARCH_RESULTS");
                    });
            })
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400) {
                    self.showToastError({ title: title, message: ex.response.data });
                } else {
                    self.showToastError({
                        title: title,
                        message: "There were some error please contact to Administrators."
                    });
                }

                self.props.hideLoading("LOAD_SEARCH_RESULTS");
            });

        this.props.showLoading("LOAD_SEARCH_RESULTS");
        return promise;
    }

    renderTitleBar = function () {
        if (this.state.selectedMainLicensePack !== '' && this.state.selectedForMerging.filter(x => x.encryptedId !== "").length !== 0) {
            return [
                <div>
                    <a href='/Licenses' className="pull-right floated right" >
                        <svg className="special__icon">
                            <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
                        </svg>
                    </a>

                    <div className="no__padding top">
                        <input type="button" className="ui primary button right align" value="Merge Licenses" aria-hidden="true" onClick={(event) => this.mergeLicenses()} />
                    </div>
                </div>];
        }
        else {
            return [
                <div>
                    <a href='/Licenses' className="pull-right floated right" >
                        <svg className="special__icon">
                            <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
                        </svg>
                    </a>

                    <div style={{ height: "105px" }} className="right align no__padding top">
                        <h3 style={{ color: "#FF8019" }}>Select License Packs for merging</h3>
                    </div>
                </div>];
        }
    }

    renderHeader = function () {
        const styleButtonHeader = {
            cursor: "pointer"
        }

        const order = this.state.orderBy.find(function (ordering) { return ordering.name === "email"; });
        const emailOrderIcon = !order ? "arrows alternate vertical" : `arrow ${(order.dir === "asc" ? "down" : "up")}`;
        return [
            <Grid columns={8}>
                <Grid.Row key="header1">
                    <Grid.Column width={2} className="panel__text--title">
                        PRODUCT
                    </Grid.Column>
                    <Grid.Column width={4} className="panel__text--title" style={{ textAlign: "left" }}>
                        {this.state.data.isAdmin ? <div onClick={this.changeEmailOrderBy} style={styleButtonHeader}>
                            EMAIL<Icon name={`${emailOrderIcon}`} /> </div> :
                            "EMAIL"}
                    </Grid.Column>
                    <Grid.Column width={1} className="panel__text--title" style={{ textAlign: "left" }}>
                        TYPE
                    </Grid.Column>
                    <Grid.Column width={1} className="panel__text--title" style={{ textAlign: "left" }}>
                        STATUS
                    </Grid.Column>
                    <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "center", fontSize: "1.08em" }}>
                        VALID FROM
                    </Grid.Column>
                    <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "center", fontSize: "1.08em" }}>
                        VALID UNTIL
                    </Grid.Column>
                    <Grid.Column width={1} className="panel__text--title">
                        #USERS
                    </Grid.Column>
                    <Grid.Column width={1} className="panel__text--title">
                        MAIN
                    </Grid.Column>
                    <Grid.Column width={1} className="panel__text--title">
                        MERGE
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row key="headerSub" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <Grid.Column width={2} className="panel__text--title">

                    </Grid.Column>
                    <Grid.Column width={4} className="panel__text--title">

                    </Grid.Column>
                    <Grid.Column width={1} className="panel__text--title">

                    </Grid.Column>
                    <Grid.Column width={1} className="panel__text--title">

                    </Grid.Column>
                    <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "12px" }}>(mm/dd/yyyy)</span>
                    </Grid.Column>
                    <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "12px" }}>(mm/dd/yyyy)</span>
                    </Grid.Column>
                    <Grid.Column width={1} className="panel__text--title">

                    </Grid.Column>
                    <Grid.Column width={1} className="panel__text--title">
                    </Grid.Column>
                </Grid.Row>
            </Grid>];
    }

    renderData = (data, selectedMainLicensePack) => {
        return data.map((item, index) => {
            return <Grid columns={8}>
                <Grid.Row key={`${index}${Math.random()}`}>
                    <Grid.Column width={2} key={`${index}a${Math.random()}`}>
                        <div className="left">
                            {this.getProductName(item.productId)}
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4} key={`${index}b${Math.random()}`}>
                        <p title={item.manager} style={{ wordWrap: "break-word" }}>{/*this.truncateEmail(item.manager)*/item.manager}</p><p>{item.name}</p>
                    </Grid.Column>
                    <Grid.Column width={1} key={`${index}c${Math.random()}`}>
                        <p style={{ textAlign: "left" }}>{String(item.trial) === 'true' ? 'Trial' : 'Full'}</p>
                    </Grid.Column>
                    <Grid.Column width={1} key={`${index}b${Math.random()}`}>
                        <p style={{ textAlign: "left" }}>{item.status}</p>
                    </Grid.Column>
                    <Grid.Column width={2} key={`${index}e${Math.random()}`}>
                        <p style={{ textAlign: "center" }}>{item.startDate}</p>
                    </Grid.Column>
                    <Grid.Column width={2} key={`${index}f${Math.random()}`}>
                        <p style={{ textAlign: "center" }}>{item.endDate}</p>
                    </Grid.Column>
                    <Grid.Column width={1} key={`${index}g${Math.random()}`}>
                        <p style={{ fontSize: item.usedCount > 99 || item.userCount > 99 ? "0.85em" : "1em" }}>{item.canManage || this.state.data.isAdmin ? item.usedCount + ' / ' + item.userCount : ''}</p>
                    </Grid.Column>
                    <Grid.Column width={1} key={`${index}h${Math.random()}`}>
                        <div>
                            <label htmlFor={`chkMainLicensePackage${index}`} style={{ fontSize: "14px" }}>
                                <input type="checkbox"
                                    defaultChecked={this.state.rememberPasswd}
                                    ref={`chkMainLicensePackage${index}`}
                                    onClick={(e) => this.selectMainLicensePack(item.encryptedId, item.productId, item.manager, item.endDate, e)} id={`chkMainLicensePackage${index}`}
                                    checked={item.encryptedId === this.state.selectedMainLicensePack}
                                    className="checkbox-button__input" />
                                <span className="checkbox-button__control"></span>
                            </label>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={1} key={`${index}h${Math.random()}`}>
                        {this.state.selectedMainLicensePack !== '' && item.encryptedId !== this.state.selectedMainLicensePack
                            ? <div>
                                <label htmlFor={`chkMergeLicensePackage${index}`} style={{ fontSize: "14px" }}>
                                    <input type="checkbox"
                                        defaultChecked={this.state.rememberPasswd}
                                        ref={`chkMergeLicensePackage${index}`}
                                        onClick={(e) => this.selectForMergingLicensePack(item.encryptedId, item.endDate, item.productId, e)}
                                        id={`chkMergeLicensePackage${index}`}
                                        checked={this.state.selectedForMerging.some(x => x.encryptedId === item.encryptedId)}
                                        className="checkbox-button__input" />
                                    <span className="checkbox-button__control"></span>
                                </label>
                            </div>
                            : null
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid >
        });
    }

    renderNoData = function () {
        return [
            <div>
                <a href='/Licenses' className="pull-right floated right" >
                    <svg className="special__icon">
                        <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
                    </svg>
                </a>

                <div className="no__padding top">
                    <h3>No information for merging</h3>
                </div>
            </div>];
    }

    renderConfirmationModalForEndDate = () => {
        return [
            <Confirm
                open={this.state.showConfirmModalForEndDate}
                onCancel={() => { this.setState({ showConfirmModalForEndDate: false }) }}
                onConfirm={this.mergeLicensesInternal}
                header="License Merge"
                content="Are you sure you want to proceed? One of the merged licenses has a longer valid date than the main license." />];
    }

    renderConfirmationModalForProductId = () => {
        return [
            <Confirm
                open={this.state.showConfirmModalForProductId}
                onCancel={() => { this.setState({ showConfirmModalForProductId: false, confirmModalForProductIdYes: false }) }}
                onConfirm={this.mergeLicensesInternal}
                header="License Merge"
                content="Are you sure you want to continue? The main license and the combined license are different products." />];
    }

    messageForCombinedConfirmationModal = (msg, item1, item2) => {
        return [
            <div>
                <p>{msg}</p>
                <List bulleted>
                    <List.Item>{item1}</List.Item>
                    <List.Item>{item2}</List.Item>
                </List>
            </div>];
    }

    renderCombinedConfirmationModal = () => {
        return [
            <Confirm
                open={this.state.showCombinedConfirmModal}
                onCancel={() => { this.setState({ showCombinedConfirmModal: false, confirmModalForEndDateYes: false, confirmModalForProductIdYes: false }) }}
                onConfirm={this.mergeLicensesInternal}
                header="License Merge"
                content={this.messageForCombinedConfirmationModal("Are you sure you want to continue?", "One of the merged licenses has a longer valid date than the main license.", "The main license and the combined license are different products.")} />];
    }

    render() {
        if (this.state.data !== undefined)
            return [this.renderTitleBar(), this.renderHeader(), this.renderData(/*data*/this.state.data.listForMerging, this.state.selectedMainLicensePack),
            this.renderConfirmationModalForEndDate(), this.renderConfirmationModalForProductId(), this.renderCombinedConfirmationModal()];
        else
            return [this.renderNoData()];
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
        successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) },
        errorMessage: function (message) { return dispatch(applicationActionCreator.notification.error(message)) }
    }
}
export default connect(null, mapDispatchToProps)(LicenseGridForMerging)
