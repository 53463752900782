import React from 'react';

import axios from 'axios';

import { withRouter, Link } from 'react-router-dom';

import { Modal, Button, Dropdown } from 'semantic-ui-react';
import { Pagination } from 'semantic-ui-react';

import AddLicense from './AddLicense';
import License from './License';
import LicensesFilter from './LicensesFilter';
import MyAccount from './MyAccount';
import PasswordSetting from './PasswordSetting';
import UserList from './UserList';
import applicationActionCreator from '../redux/actions/applicationActions';
import { connect } from 'react-redux';

class ContentBlockList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searching: false,
            adding: true,
            lastSearchValue: '',
            addMode: false,
            licenseUserlist: [],
            licenseId: null,
            usedCount: 0,
            userCount: 0,
            editmode: false,
            detailId: 0,
            detailMode: false,
            removeMode: false,
            isAdmin: false,
            currentLicense: {
                canShowRoles: false
            },
            modal: {
                open: false,
                errorMessage: '',
                successMessage: ''
            },
            pageSize: 10,
            pageNumber: 1,
            totalPages: 0,
            queryProduct: 0,
            queryType: '',
            queryStatus: 'Active',
            pagedFilterUpdated: true,
            isMerging: false,
            addLicenseRef: null,
            mergeLicenseRef: null
        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.updateLicenses = this.updateLicenses.bind(this);
        this.updateLicenseUser = this.updateLicenseUser.bind(this);
        this.handleLicenseUsageFilter = this.handleLicenseUsageFilter.bind(this);
        this.handleLicenseFilter = this.handleLicenseFilter.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.getLicenseId = this.getLicenseId.bind(this);
        this.getUsedCount = this.getUsedCount.bind(this);
        this.getUserCount = this.getUserCount.bind(this);
        this.updateUsedCount = this.updateUsedCount.bind(this);
        this.getLicenseList = this.getLicenseList.bind(this);
        this.removeHandler = this.removeHandler.bind(this);
        this.loadLicenseDataRows = this.loadLicenseDataRows.bind(this);
        this.handleLicensePageChanged = this.handleLicensePageChanged.bind(this);
        this.handleLicenseGridFilterChanged = this.handleLicenseGridFilterChanged.bind(this);
        this.handleLicenseGridFilterInternal = this.handleLicenseGridFilterInternal.bind(this);
        this.searchLicensePaged = this.searchLicensePaged.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.getLicenseManagementOptions = this.getLicenseManagementOptions.bind(this);
        this.handleAction = this.handleAction.bind(this);
    }

    showErrorMessage = (message) => {
        this.setState({ modal: { open: true, errorMessage: message, type: "error", successMessage: '' } });
    }

    showSuccessMessage = (message) => {
        this.setState({ modal: { open: true, successMessage: message, type: "success", errorMessage: '' } });
    }

    closeMessageModal = () => {
        this.setState({ modal: { open: false } });

    }

    onCompletedEdition = () => {
        this.setState({ detailId: null, editmode: false });
    }

    removeHandler(canRemove) {
        this.setState((prevState) => ({
            ...prevState,
            removeMode: canRemove
        }))
    }

    onDetailSelectionChanged = (detailId) => {
        this.setState({ detailId, editmode: detailId != null });
    }

    handleAdd() {

        this.props.history.push('license/new');

        //this.setState({addMode:true});
    }

    handleMerge() {
        this.setState({
            isMerging: true
        }, () => this.handleLicenseFilter(this.state.lastSearchValue));
    }

    handleCancel() {
        this.setState({ addMode: false });
    }

    updateLicenses(locationUrl) {
        const self = this;
        return axios.get(locationUrl)
            .then(function (response) {
                if (response) {
                    self.props.history.push('./Licenses');
                }
            })
            .catch(function (e) {
                console.error(e.status, e.toString());
            });
    }

    updateLicenseUser(locationUrl) {
        return axios.get(locationUrl)
            .then(function (response) {
                if (response) {
                    this.state.licenseUserlist.unshift(response.data);
                    this.setState({
                        licenseUserlist: this.state.licenseUserlist,
                    });
                }
            })
            .catch(function (e) {
                console.error(e.status, e.toString());
            })

    }

    handleLicenseUsageFilter(value) {
        const requesturl = 'api/Licenses/' + value + '/LicenseUsages';
        const self = this;
        return axios.get(requesturl)
            .then(function (response) {
                if (response && response.data) {
                    self.setState({ licenseUserlist: response.data });
                    self.props.history.push('./LicenseUsages');
                }
            })
            .catch(function (e) {
                console.error(e.status, e.toString());
            });
    }

    handleLicenseFilter(value) {
        const self = this;
        if (value) {
            self.setState(
                {
                    pageNumber: 1,
                    totalPages: 0,
                    lastSearchValue: value, pagedFilterUpdated: false
                },
                self.handleLicenseGridFilterInternal);
        }


    }
    getUserList = function (dataFromChild) {
        this.setState({ licenseUserlist: dataFromChild });
    }
    getLicenseId = function (data) {
        this.setState({ licenseId: data });
    }
    getUsedCount = (data) => {
        this.setState({ usedCount: data });
    }
    getUserCount = (data) => {
        this.setState({ userCount: data });
    }

    updateUsedCount = (data) => {
        this.setState((prevState) => {
            return { usedCount: +prevState.usedCount + data };
        });
    }

    componentCanAddlicense() {
        const componentAddLicense = ['Licenses', 'License', ''];
        return componentAddLicense.filter(x => x === this.props.data.detailType).length > 0;
    }

    getLicenseList() {
        if (!this.props.data.isAdmin) {
            this.handleLicenseFilter('/api/Licenses');
        } else {
            this.handleLicenseFilter(this.state.lastSearchValue);
        }
        this.props.detailType('License');
        this.props.match.params.detailName = 'License';
    }

    getMyAccount() {
        this.handleLicenseFilter('/api/MyAccount');
        this.props.detailType('MyAccount');
        this.props.match.params.detailName = 'MyAccount';
    }

    getPasswordSettings = () => {
        this.props.detailType('PasswordSetting');
        this.props.match.params.detailName = 'PasswordSetting';
    }

    onAddNewLicenseUsageHandler = (licenseUsage) => {
        const self = this;
        self.props.showLoading("AddNewUSage");
        this.setState({ adding: false });
        axios.post(`api/Licenses/${self.state.licenseId}/LicenseUsages`, licenseUsage)
            .then((response) => {

                var licenseUserList = self.state.licenseUserlist;
                licenseUserList.push(response.data);
                self.setState({ licenseUserList: licenseUserList, addMode: !self.state.addMode });
                if (response) {
                    self.showSuccessMessage('The new user license was successfully assigned.');
                    self.updateUsedCount(1);
                    self.setState({ addMode: !self.state.addMode });
                }
            }).catch((e) => {
                self.updateUsedCount(-1);
                console.log(e);
                console.error(self.props.match.url, e.status, e.toString());
                if (e.response && e.response.data !== "")
                    self.showErrorMessage(`Email:'${licenseUsage.accountName}' - ${e.response.data}`);
                else
                    self.showErrorMessage(`Email:"${licenseUsage.accountName}" has already been assigned a license, please revoke it before trying to assign it to a new license.`);
            }).then(() => {
                self.setState({ adding: true });
                self.props.hideLoading("AddNewUSage");
                self.handleLicenseFilter(self.state.licenseId);
            });
    }

    setCurrentLicense = (license) => {
        this.setState({ currentLicense: license });
    }

    handleGetSessionsFileClick = () => {
        const self = this;
        if (self.state.licenseId !== null)
            window.location.href = `/api/Session/Csv/${self.state.licenseId}`;
    }

    handleLicensePageChanged = function (event, data) {
        const self = this;
        this.setState({ pageNumber: data.activePage, pagedFilterUpdated: false }, () => {
            if (self.state.queryProduct != 0 || self.state.queryType != '' || self.state.queryStatus != '') {

                self.props.showLoading("SearchLicenses1");
                self.searchLicensePaged();
            } else {
                self.loadLicenseDataRows(self.state.lastSearchValue, data.activePage, self.state.pageSize);
            }
        });


    }

    loadLicenseDataRows = function (value, pageNumber, pageSize) {
        if (!value) return;
        this.setState({ searching: true, lastSearchValue: value });
        this.props.showLoading("LoadLicenseData");
        const self = this;
        const requesturl = (!value || /^\s*$/.test(value)) && self.props.data.isAdmin ? 'api/Licenses' : 'api/Licenses/UsersLicensePaged?emailPart=' + value + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
        return axios.get(requesturl)
            .then(function (response) {
                if (response) {
                    self.props.data.list = response.data;
                    self.setState(self.props.data);
                    self.setState({ isAdmin: self.props.data.isAdmin });
                    self.setState({ searching: false });
                }
            })
            .catch(function (e) {
                console.error(e.status, e.toString());
            })
            .then(function () {
                self.props.hideLoading("LoadLicenseData");
            })

    }

    loadTotalLicensesCount = function (emailPart) {
        if (this.state.totalPages == 0) {
            const self = this;
            return axios.get('api/Licenses/TotalUsersLicenses?emailPart=' + emailPart)
                .then(res => {
                    let totalPages = Math.ceil(res.data / self.state.pageSize);
                    self.setState({ totalPages: totalPages });
                    self.loadLicenseDataRows(emailPart, self.state.pageNumber, self.state.pageSize);
                });
        } else {
            this.loadLicenseDataRows(emailPart, this.state.pageNumber, this.state.pageSize);
        }
    }

    handleLicenseGridFilterChanged = function (queryProduct, queryStatus,
        queryEmail, queryType, orderBy, filter) {
        const self = this;
        let value = self.state.lastSearchValue;
        self.setState({
            queryEmail: value,
            queryType,
            queryStatus,
            queryProduct,
            pagedFilterUpdated: true,
            filter,
            orderBy
        },
            self.handleLicenseGridFilterInternal);
    }

    searchLicensePaged = function () {
        const { queryStatus, queryType, queryProduct, orderBy, filter, isMerging } = this.state;
        const self = this;
        let value = this.state.lastSearchValue;
        if (!value && self.props.data.isAdmin) return new Promise(function (resolve) {
            resolve([]);
        });
        const pageSize = isMerging ? 300 : this.state.pageSize;
        const params = {
            ...filter,
            emailPart: value,
            type: queryType,
            status: queryStatus,
            productId: queryProduct,
            pageSize: pageSize,
            pageNumber: this.state.pageNumber,
            ...(orderBy || []).find(function (x) {
                return !!x.name;
            })
        }
        const requesturl = (!value || /^\s*$/.test(value)) && self.props.data.isAdmin ? 'api/Licenses' : 'api/Licenses/SearchLicensePaged';
        self.props.showLoading("SearchLicenses");
        return axios.get(requesturl, { params })
            .then(function (response) {
                if (response) {
                    self.props.data.list = response.data;
                    //this.setState({ totalPages: 1 });
                    self.setState(self.props.data);
                    self.setState({ isAdmin: self.props.data.isAdmin });
                    self.setState({ searching: false });

                    if (isMerging) {
                        self.props.data.listForMerging = response.data;
                        self.props.history.push({
                            pathname: '/license/merging',
                            state: self.props.data
                        });
                    }
                }
            })
            .catch(function (e) {
                console.error(e.status, e.toString());
            })
            .then(function () {
                self.props.hideLoading("SearchLicenses");
            });
    }

    handleLicenseGridFilterInternal = function () {
        const { queryStatus, queryType, queryProduct, filter, isMerging } = this.state;
        const self = this;
        let value = this.state.lastSearchValue;
        const pageSize = isMerging ? 300 : this.state.pageSize;
        const params = {
            ...filter,
            emailPart: value,
            type: queryType,
            status: queryStatus,
            productId: queryProduct,
            pageSize: pageSize,
            pageNumber: 1
        }
        const requesturl = (!value || /^\s*$/.test(value)) && self.props.data.isAdmin ? 'api/Licenses' : 'api/Licenses/SearchLicensePagedCount';
        this.props.showLoading("LOAD_SEARCH_RESULTS");
        return axios.get(requesturl, { params })
            .then(function (response) {
                if (response) {
                    let totalPages = Math.ceil(response.data / pageSize);
                    return new Promise(function (resolve) {
                        self.setState({ totalPages: totalPages, pageNumber: 1 }, resolve);
                    })
                }
            })
            .then(function () {
                self.props.showLoading("LOAD_SEARCH_RESULTS2");
                return self.searchLicensePaged()
                    .then(function () {
                    });
            })
            .catch(function (e) {
                console.error(e.status, e.toString());
            })
            .then(function () {
                self.props.hideLoading("LOAD_SEARCH_RESULTS");
                self.props.hideLoading("LOAD_SEARCH_RESULTS2");
            }
            );

    }

    onSelectionFileSuccess = (fileObject) => {
        const self = this;

        const formData = new FormData();
        formData.append('file', fileObject)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        self.props.showLoading("SearchLicenses2");
        axios.post(`/api/Licenses/${self.state.licenseId}/LicenseUsages/UploadCSV`, formData, config)
            .catch((e) => {
                self.showErrorMessage(e.response.data.join('<br>'));
            }).then(function () {

                self.props.showLoading("SearchLicenses43");
            });
    }

    getLicenseManagementOptions(item) {
        const items = [
            { text: "Add License" },
            { text: "Merge License" }
        ];

        let options = [
        ];

        for (let item of items) {
            options = [...options,
            <Dropdown.Item
                style={{ color: '#FF8019' }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleAction}>
                {item.text}
            </Dropdown.Item>
            ];
        }
        return options;
    }

    handleMouseEnter(e) {
        e.target.style.background = '#FF8019';
        e.target.style.color = 'white';
    }

    handleMouseLeave(e) {
        e.target.style.background = 'white';
        e.target.style.color = '#FF8019';
    }

    handleAction(e) {
        switch (e.target.innerText) {
            case "Add License":
                this.addLicenseRef.ref.click();
                break;

            case "Merge License":
                this.mergeLicenseRef.ref.click();
                break;

            default:
                break;
        }
    }

    render() {
        let addLicenseForm;
        let addLicenseUserForm;
        let licenseRows;
        let usersGrid;
        let productGrid;
        let licenseUsageRows;
        let title;
        let licenseFilter;
        let mergeLicensesButton;
        let toggleAddButton;
        let licenseManagementButton;
        let userInformationForm;
        let backButton;
        let productDetailForm;
        let editmode = false;
        let passwordSettingsButton;
        let passwordSettingsView;
        let hiddenAddLicenseButton;

        addLicenseForm = this.props.data.detailType === 'License' && this.state.addMode === true && this.props.data.isAdmin === true ?
            <div className="ui grid">
                <div className="eight wide column centered">
                    <h3 className="ui centered">Create License</h3>
                    <AddLicense data={this.props.data.list}
                        onCancel={this.handleCancel} onInsert={this.updateLicenses} editmode={editmode} />
                </div>
            </div>
            : null;

        usersGrid = this.props.data.detailType === 'ManageUser' && this.state.addMode === false && this.props.data.isAdmin === true ?
            <UserList searching={this.state.searching} handleFilter={this.handleLicenseFilter} /> :
            null;
        passwordSettingsView = this.props.isManager && this.props.data.detailType === 'PasswordSetting' ?
            <PasswordSetting /> : null;
        userInformationForm = this.props.data.detailType === 'MyAccount' ?
            <div>
                {/*<MyAccount data={this.props.data.list} onCancel={this.handleCancel} onInsert={this.updateLicenses} editmode={editmode}*/}
                {/*  onUserChange={this.props.onUserChange} />*/}
                <MyAccount />
            </div>
            : null;

        passwordSettingsButton = this.props.data.detailType === 'MyAccount' && this.props.isManager ?
            <Link to='/PasswordSettings' onClick={this.getPasswordSettings} className="ui button black right align huge">
                Password Settings
            </Link>
            : null;

        backButton = this.props.data.detailType === 'PasswordSetting' ?
            <div className="ui black basic mini right">
                <Link to='/MyAccount' onClick={() => this.props.detailType("MyAccount")}>
                    <svg className="special__icon">
                        <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
                    </svg>
                </Link>
            </div> : null;
        licenseRows = this.props.data.detailType === 'License' ?
            <div>
                <License setUserList={this.getUserList} setLicenseId={this.getLicenseId}
                    setUsedCount={this.getUsedCount} setUserCount={this.getUserCount}
                    setCurrentLicense={this.setCurrentLicense}
                    data={this.props.data.list} detailType={this.props.detailType}
                    isAdmin={this.props.data.isAdmin} onLicenseFilterChanged={this.handleLicenseGridFilterChanged} />
                {this.props.data.list.length > 0 && this.state.totalPages > 1 &&
                    <Pagination
                        defaultActivePage={1}
                        totalPages={this.state.totalPages} onPageChange={this.handleLicensePageChanged} style={{ float: "right", marginRight: "120px" }} />}
            </div> : null;


        licenseFilter = this.props.data.detailType === 'License' && this.state.addMode === false && this.props.data.isAdmin === true ?
            <LicensesFilter searching={this.state.searching} handleFilter={this.handleLicenseFilter} /> :
            null;


        /*<input type="button" className="ui secondary button blue right align large" value="MERGE LICENSES" style={{ backgroundColor: "#009edf", display: "none" }} aria-hidden="true" onClick={(event) => this.handleMerge() || this.props.onNavigationEvent(event)} />*/
        mergeLicensesButton = this.props.data.detailType === 'License' && this.state.addMode === false && this.props.data.isAdmin === true && (typeof (this.props.data.list) !== 'undefined') && this.props.data.list.length > 1
            ? <Button ref={x => this.mergeLicenseRef = x} content="MERGE LICENSES" style={{ backgroundColor: "#009edf", display: "none" }} aria-hidden="true" onClick={(event) => this.handleMerge() || this.props.onNavigationEvent(event)} />
            : null;

        let preventAddLicense = (this.props.data.isAdmin === false && this.props.data.detailType === 'License') || !this.componentCanAddlicense();
        toggleAddButton = this.state.addMode === true ?
                <input type="button" className="ui secondary button right align large" value="BACK" aria-hidden="true" onClick={this.handleCancel} />
                : null;

        hiddenAddLicenseButton = <Button ref={x => this.addLicenseRef = x} style={{ backgroundColor: "#009edf", display: "none" }} content="ADD LICENSE" aria-hidden="true" onClick={(event) => this.handleAdd() || this.props.onNavigationEvent(event)} />;

        licenseManagementButton = mergeLicensesButton !== null && toggleAddButton === null ?
            <div className='im_dropdown_button_field right align'>
                <Dropdown
                    button
                    text='Manage Licenses'>
                    <Dropdown.Menu>
                        {this.getLicenseManagementOptions()}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            : mergeLicensesButton === null && toggleAddButton === null && !preventAddLicense ?
                <input type="button" className="ui primary button right align large" value="ADD LICENSE" aria-hidden="true" onClick={(event) => this.handleAdd() || this.props.onNavigationEvent(event)} data-toggle="tooltip" title="Add single Fs Pro license" />
                : mergeLicensesButton === null && toggleAddButton !== null ?
                    <input type="button" className="ui secondary button right align large" value="BACK" aria-hidden="true" onClick={this.handleCancel} />
                    : null;

        title = this.props.data.detailType === 'License' ?
            <h1 className="headline-primary" style={{ marginLeft: "10px" }}>Licenses</h1>
            : <h1 className="headline-primary">{this.props.data.title}</h1>;

        return (
            <div className="panel__inverse">
                <Modal
                    open={this.state.modal.open}
                    closeOnEscape={true}
                    closeOnDimmerClick={false}
                    className={this.state.modal.type}
                    onClose={this.closeMessageModal}
                    positive={this.state.modal.errorMessage === "" || this.state.modal.errorMessage === null ? "false" : "true"}
                >
                    <Modal.Header>
                        <p></p>
                    </Modal.Header>
                    <Modal.Content>
                        <p dangerouslySetInnerHTML={{ __html: this.state.modal.errorMessage + this.state.modal.successMessage }}></p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeMessageModal} positive={this.state.modal.successMessage !== ""} negative={this.state.modal.errorMessage !== ""}>
                            Ok
                        </Button>
                    </Modal.Actions>
                </Modal>
                <div>
                    {backButton}
                    {passwordSettingsButton}
                    {toggleAddButton}
                    {hiddenAddLicenseButton}
                    {mergeLicensesButton}
                    {licenseManagementButton}
                    {title}
                </div>
                <div className="no__padding">
                    {licenseFilter}
                </div>
                <div className="no__padding">
                    {passwordSettingsView}
                    {addLicenseForm}
                    {productDetailForm}
                    {usersGrid}
                    {productGrid}
                    {userInformationForm}
                    {licenseRows}
                </div>
                <div className="no__padding" style={{ paddingBottom: "50px" }}>
                    {licenseUsageRows}
                    {addLicenseUserForm}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) }
    }
}

export default withRouter(connect(null, mapDispatchToProps)(ContentBlockList));
