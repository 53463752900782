import React from 'react';

import axios from 'axios';

import ReactTable from 'react-table';

import { withRouter } from 'react-router-dom';

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data, usersToggleAdmin: [] };
    this.loadUsersFromServer();
    this.handleSelected = this.handleSelected.bind(this);
    this.toggleAdministratorPermission = this.toggleAdministratorPermission.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  saveChanges() {
    return axios.put('/api/Users', this.state.usersToggleAdmin)
      .then(function (response) {
        if (response) {
          this.loadUsersFromServer();
          this.setState({ usersToggleAdmin: [] });
        }
      })
      .catch(function (e) {
        console.error(this.props.match.url, e.status, e.toString());
      });
  }

  handleSelected(key, value) {
    //this.props.handleFilter(value);
  }

  toggleAdministratorPermission(e) {
    const users = this.state.data;
    var usersAdminChanged = this.state.usersToggleAdmin;
    var userId = e.target.id;
    if (usersAdminChanged.findIndex(u => u === userId) > -1) {
      usersAdminChanged = usersAdminChanged.filter(u => u !== userId);
    }
    else {

      usersAdminChanged.push(e.target.id);
    }
    var userIndex = users.findIndex(u => u.id.toString() === userId);
    users[userIndex].administrator = !users[userIndex].administrator;
    this.setState({ data: users, usersToggleAdmin: usersAdminChanged });
  }


  handleLicenseUsageFilter(e) {
    e.persist()
    const licenseId = e.target.id;
    const userCount = e.target.getAttribute('usercount');
    const usedCount = e.target.getAttribute('usedcount');
    const requesturl = 'api/Licenses/' + licenseId + '/LicenseUsages';
    this.setState({ manage: false });
    const self = this;
    return axios.get(requesturl)
      .then(function (response) {
        if (response) {
          self.props.setUserList(response.data);
          self.props.setLicenseId(licenseId);
          self.props.setUserCount(userCount);
          self.props.setUsedCount(usedCount);
          self.props.detailType('LicenseUsage');
          self.props.match.params.detailName = 'LicenseUsage';
          self.props.history.push('./LicenseUsages');
        }

      })
      .catch(function (e) {
        console.error(e.status, e.toString());
      });
  }

  loadUsersFromServer() {
    return axios.get('/api/Users')
      .then(function (response) {
        if (response) {
          this.setState((prevState) => ({
            ...prevState,
            data: response.data
          }));
        }

      })
      .catch(function (e) {
        console.error(this.props.match.url, e.status, e.toString());
      });
  }


  render() {
    const columns = [{
      Cell: () => (
        <div>
          {/* <svg className="license__icon">
            <use xlinkHref="img/sprite/sprite.svg#svg--FSPro"></use>
            </svg>*/}
          <img className="user__icon" src="img/user.png" alt="license icon" />
        </div>
      ),
      maxWidth: 40,
      filterable: false,
      sortable: false
    }, {
      Cell: () => (
        <div></div>
      ),
      maxWidth: 40,
      filterable: false,
      sortable: false
    }, {
      Cell: (cellInfo) => (
        <div>
          {cellInfo.original.firstName + " " + cellInfo.original.lastName}
        </div>
      ),
      Header: 'Name ',
      maxWidth: 350,
      filterable: false,
      sortable: false
    }, {
      Cell: (cellInfo) => (
        <button type="button" className={(cellInfo.original.administrator ? 'blue' : 'gray') + " ui button"}
          id={cellInfo.original.id} onClick={this.toggleAdministratorPermission}
        > Administrator</button>
      ),
      maxWidth: 350,
      filterable: false,
      sortable: false
    }
    ];
    return (
      <div>
        <button onClick={this.saveChanges} disabled={this.state.usersToggleAdmin.length === 0}
          className={(this.state.usersToggleAdmin.length !== 0 ? 'blue' : 'gray') + " align right ui button"}>Save</button>
        <ReactTable
          columns={columns}
          data={this.state.data}
          filterable
          defaultPageSize={5}
          noDataText={'There are no users to show.'}
        />
      </div>
    );
  }
}

export default withRouter(UserList);
