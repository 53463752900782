import React from 'react';
import axios from 'axios';
import { Form, Grid, Button } from 'semantic-ui-react';

class PasswordSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minChars: 8,
      upperChars: 1,
      numSpecialChars: 1,
      minimunChars:8,
      expirationMonths: 1,
      errorMessage: '',
      successMessage: '',
      passwordHistory: false,
      twoFA: false
    };
    this.loadPasswordSetting();
  }
  componentDidMount() {
  }

  loadPasswordSetting = () => {
    const self = this;
    axios.get('/api/PasswordSettings/CurrentUser')
      .then(function (response) {
        self.setState({
          minChars: response.data.minimunChars,
          upperChars: response.data.uppercaseChars,
          numSpecialChars: response.data.specialChars,
          expirationMonths: response.data.expirationMonths,
          twoFA: response.data.twoFA,
          passwordHistory: response.data.passwordHistory,
        });
      })
  }
  handleCounterChange = (e, { name, value }) => {
    let minChars = 8;
    switch (name) {
      case "numSpecialChars":
        minChars = parseInt(value) + parseInt(this.state.upperChars);
        break;
      case "upperChars":
        minChars = parseInt(this.state.numSpecialChars) + parseInt(value);
        break;
        default:
        break;
    }
    if (minChars>=8)
      this.setState({
        minimunChars: minChars,
        minChars: this.state.minChars < minChars ? minChars :  this.state.minChars
      });
    this.setState({ [name]: value })
  }

  onSubmitHandler = () => {
    const self = this;
    this.setState({ errorMessage: '', successMessage: '' });
    self.props.showLoading("UpdatePasswordSettings");
    axios.post('/api/PasswordSettings', {
      minimunChars: this.state.minChars,
      uppercaseChars: this.state.upperChars,
      specialChars: this.state.numSpecialChars,
      expirationMonths: this.state.expirationMonths,
      twoFA: this.state.twoFA,
      passwordHistory: this.state.passwordHistory
    })
      .then(function () {
          self.setState({ successMessage: 'Password settings saved successfully. ' });
      })
      .catch(function (error) {
        var errorMessage = error.response.data;
        self.setState({ errorMessage });
      }).finally(() => {
        self.props.hideLoading("UpdatePasswordSettings");
      });
  }

  render() {
    let view = (
      <div className="grid">
        {(this.state.errorMessage !== '') ? <div className="ui negative message"><div className="header">Error</div><p>{this.state.errorMessage}</p></div> : ''}
        {(this.state.successMessage !== '') ? <div className="ui positive message"><div className="header">Saved</div><p>{this.state.successMessage}</p></div> : ''}
        <div id="getemail" className="centered grid__col--5">
          <Form onSubmit={this.onSubmitHandler} >
            <Grid>
              <Grid.Row>
              </Grid.Row>
              <Grid.Row >
                <Form.Input
                  label={`Min. Characters: ${this.state.minChars}`}
                  min={this.state.minimunChars}
                  max={30}
                  name='minChars'
                  onChange={this.handleCounterChange}
                  step={1}
                  type='range'
                  value={this.state.minChars}
                />
              </Grid.Row>
              <Grid.Row>
                <Form.Input
                  label={`Min. Uppercase Characters: ${this.state.upperChars}`}
                  min={1}
                  max={10}
                  name='upperChars'
                  onChange={this.handleCounterChange}
                  step={1}
                  type='range'
                  value={this.state.upperChars}
                />
              </Grid.Row>
              <Grid.Row>
                <Form.Input
                  label={`Min. Special or Number Characters: ${this.state.numSpecialChars}`}
                  min={1}
                  max={10}
                  name='numSpecialChars'
                  onChange={this.handleCounterChange}
                  step={1}
                  type='range'
                  value={this.state.numSpecialChars}
                />
              </Grid.Row>
              <Grid.Row>
                <h1 className="ui header centered">Password Expiration</h1>
              </Grid.Row>
              <Grid.Row >
                <Form.Input
                  label={`Expiration Password: ${this.state.expirationMonths} months`}
                  min={1}
                  max={12}
                  name='expirationMonths'
                  onChange={this.handleCounterChange}
                  step={1}
                  type='range'
                  value={this.state.expirationMonths}
                />
              </Grid.Row>
              <Grid.Row>
                <h1 className="ui header centered">Password History</h1>
                <h4 className="ui header centered">last five passwords will be saved</h4>
              </Grid.Row>
              <Grid.Row>
                <Button onClick={() => this.setState({ passwordHistory: !this.state.passwordHistory })} type="button"
                  color={this.state.passwordHistory ? 'primary' : 'grey'}>{this.state.passwordHistory ? 'Enabled' : 'Disabled'}</Button>
              </Grid.Row>
              <Grid.Row>
                <h1 className="ui header centered">Two Factor Authentication</h1>
              </Grid.Row>
              <Grid.Row>
                <Button onClick={() => this.setState({ twoFA: !this.state.twoFA })} type="button"
                  color={this.state.twoFA ? 'blue' : 'grey'}>{this.state.twoFA ? 'Enabled' : 'Disabled'}</Button>
              </Grid.Row>
              <Grid.Row>
                <Button color='primary' className="large" >Submit</Button>
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </div>
    );
    return view;
  }
}

export default PasswordSetting;