import React from 'react';

import axios from 'axios';

import IMComponent from '../Components/IMComponent';
import { confirmationModal } from '../Components/ModalComponent';
import applicationActionCreator, { actions } from '../../redux/actions/applicationActions'
import ProductsGrid from './ProductsGrid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class List extends IMComponent {
  constructor(props) {
    super(props);
    this.state = { products: [] }
    this.showProductEdit = this.showProductEdit.bind(this);
    this.loadProductsFromServer = this.loadProductsFromServer.bind(this);
    this.loadProductsFromServer();
  }

  showProductEdit(row) {
    const productId = row.target.id;
    this.props.goToPage(`/product/edit/${productId}`);
  }

  deleteProduct = function (row, productName) {
    const productId = row.target.id;
    const self = this;
    confirmationModal('Delete Product?', `Are you sure to delete '${productName}' product?`)
      .then(function () {
        self.props.showLoading('DELETE_PRODUCT');
        return axios.delete(`/api/products/${productId}`);

      })
      .then(function () {
        self.loadProductsFromServer();
        self.showToastSuccess({ title: "Product", message: "Deleted successfully." })

      })
      .catch(function (e) {
        if (e)
          console.error(self.props.match.url, e.status, e.toString());
      })
      .then(function () {
        self.props.hideLoading('DELETE_PRODUCT')
      })
  }

  showProductNew = () => {
    this.props.goToPage("/product/new");
  }

  loadProductsFromServer() {
    const self = this;
    return axios.get('/api/products')
      .then(function (response) {

        if (response) {
          self.setState({ products: response.data });
        }
      })
      .catch(function (e) {
        console.error(self.props.match.url, e.status, e.toString());
      });
  }


  render() {
    const columns = [{
      Cell: () => (
        <div>
          <img className="user__icon" src="img/user.png" alt="user icon" />
        </div>
      ),
      maxWidth: 40,
      filterable: false,
      sortable: false
    }, {
      Cell: () => (
        <div></div>
      ),
      maxWidth: 40,
      filterable: false,
      sortable: false
    }, {
      Cell: (cellInfo) => (
        <div>
          {cellInfo.original.name}
        </div>
      ),
      Header: 'Name ',
      maxWidth: 350,
      filterable: false,
      sortable: false
    }, {
      Cell: (cellInfo) => ([
        <button type="button" className='ui button primary btn'
          id={cellInfo.original.productId}
          onClick={this.showProductEdit}
        > <i className="fas fa-pencil-alt"></i> Edit</button>,
        <button type="button" className='ui button red btn'
          id={cellInfo.original.productId}
          onClick={(r) => this.deleteProduct(r, cellInfo.original.name)}
        > <i className="fas fa-trash-alt"></i>  Delete</button>]
      ),
      maxWidth: 350,
      filterable: false,
      sortable: false
    }
    ];
    return (
      <div>
        <a href='/Licenses' className="pull-right float right" >
          <svg className="special__icon">
            <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
          </svg>
        </a>
        <h1 className="headline-primary">Products</h1>
        <button onClick={this.showProductNew}
          className='ui button primary btn pull-right'>
          <i className='fas fa-plus'></i> Add Product</button>
        <ProductsGrid data={this.state.products} deleteProduct={this.deleteProduct} showProductEdit={this.showProductEdit} />
      </div>
    );
  }
}



function mapDispatchToProps(dispatch) {
  return {
      showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
      hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
      successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) },
      errorMessage: function (message) { return dispatch(applicationActionCreator.notification.error(message)) },
  }
}

export default connect(null, mapDispatchToProps)(List);