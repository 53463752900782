import React from 'react';

import axios from 'axios';

import { Dropdown, Input, Popup } from 'semantic-ui-react';

import { countryOptions } from './Semantic-ui-options';

import { getPasswordValidation } from './utility/Helper';

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      countryCode: null,
      validations: [],
      data: {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",
        countryCode: "",
        createdAt: "",
        modifiedAt: "",
        password: "",
        confirmPassword: "",
        enablePassword: false,
        hasErrors: false,
        errorMessage: null,
        success: false
      }
    };
    this.loadCountriesAvailable();
    this.loadUserInformation();
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleEnablePasswordChange = this.handleEnablePasswordChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);
    this.loadPasswordSetting();
  }


  loadPasswordSetting() {
    this.props.showLoading("LoadPasswordSettings");
    const self = this;
    axios.get(`/api/PasswordSettings/CurrentUser`)
      .then((response) => {
        self.setState({ passwordSetting: response.data });
        self.onPasswordChange("");
      })
      .catch((e) => { self.setState({ errorMessage: e.response.data }); })
      .then(() => {
        self.props.hideLoading("LoadPasswordSettings");
      });
  }

  loadUserInformation() {
    const self = this;
    return axios.get('/api/User',{params:{version:Math.random()}})
      .then(function (userInformation) {
        const currentCountryCode = self.state.countries.find(x => x.key === userInformation.data.countryCode);
        self.setState((prevState) => ({
          isManager: userInformation.data.isManager,
          data: {
            ...prevState.data,
            firstName: userInformation.data.firstName,
            company: userInformation.data.company,
            lastName: userInformation.data.lastName,
            email: userInformation.data.email,
            createdAt: userInformation.data.createdAt,
            phone: userInformation.data.phone,
            countryCode: userInformation.data.countryCode,
            phoneCode: currentCountryCode !== undefined ? currentCountryCode.code : "",
            modifiedAt: userInformation.data.modifiedAt
          }
        }));
      })
  }

  handleCountryChange = (e, d) => {
    var country = { code: null, value: "" };
    if (d.value !== "")
      country = countryOptions.find(x => x.key === d.value);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        phoneCode: country.code,
        countryCode: country.value
      }
    }));
  }

  loadCountriesAvailable() {
    const self = this;
    return axios.get('/api/CountriesPhone')
      .then(function (countries) {
        self.setState({
          countries: countryOptions.filter(x => countries.data.includes(x.key))
        });

      });
  }
  handleCompanyChange(e) {
    e.persist()
    this.setState((prevState) => (
      { data: { ...prevState.data, company: e.target.value } }));
  }
  handleConfirmPasswordChange(e) {
    e.persist()
    this.setState((prevState) => (
      { data: { ...prevState.data, confirmPassword: e.target.value } }));
  }
  handleEnablePasswordChange(e) {
    e.persist()
    this.setState((prevState) => (
      {
        data: {
          ...prevState.data,
          enablePassword: !this.state.data.enablePassword,
          password: "",
          confirmPassword: ""
        },
        validations: getPasswordValidation("", this.state.passwordSetting)
      }));
  }

  onPasswordChange = (newVal) => {
    var validations = getPasswordValidation(newVal, this.state.passwordSetting);

    this.setState((prevState) => (
      {
        data: { ...prevState.data, password: newVal },
        validations
      }));
  }

  handlePasswordChange(e) {
    var newVal = e.target.value;
    this.onPasswordChange(newVal);
  }
  handleFirstNameChange(e) {
    e.persist()
    this.setState((prevState) => (
      { data: { ...prevState.data, firstName: e.target.value } }));
  }
  handleLastNameChange(e) {
    e.persist()
    this.setState((prevState) => (
      { data: { ...prevState.data, lastName: e.target.value } }));
  }
  handleEmailChange(e) {
    e.persist()
    this.setState((prevState) => (
      { data: { ...prevState.data, email: e.target.value } }));
  }

  handleCountryCodeChange = (e) => {
    this.setState((prevState) => (
      { data: { ...prevState.data, countryCode: e.target.value } }));
  }

  onPhoneNumberChange = (e, d) => {
    console.log(d)
    this.setState((prevState) => (
      { data: { ...prevState.data, phone: d.value } }));
  }

  handleUpdateSubmit(e) {
    e.preventDefault();
    if (this.state.data.enablePassword === true) {
      let errorMessage = null;

      if (this.state.data.confirmPassword !== this.state.data.password)
        errorMessage = "Password did not match, try again!";

      if (!this.state.data.password || this.state.data.password === "")
        errorMessage = "Password can not be blank, try again!";

      if (errorMessage && errorMessage !== "") {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            errorMessage: errorMessage,
            hasErrors: true,
            success: false
          }
        }));
        return;
      }
    }
    const self = this;
    self.props.showLoading("UpdateAccountInfo");
    return axios.put('/api/User',
      {
        FirstName: this.state.data.firstName,
        company: this.state.data.company,
        LastName: this.state.data.lastName,
        Email: this.state.data.email,
        phone: this.state.data.phone,
        countryCode: this.state.data.countryCode,
        Password: this.state.data.enablePassword ? this.state.data.password : ""
      }
    ).then((response) => {

      self.props.onUserChange();
      self.loadUserInformation();
      self.setState((prevState) => ({
        data: {
          ...prevState.data,
          hasErrors: false,
          errorMessage: "",
          enablePassword: "",
          password: "",
          confirmPassword: "",
          success: true
        }
      }));
      self.props.hideLoading("UpdateAccountInfo");
    })
      .catch((e) => {
        self.setState((prevState) => ({
          data: {
            ...prevState.data,
            errorMessage: e.response.data,
            hasErrors: true,
            success: false
          }
        }));
        self.props.hideLoading("UpdateAccountInfo");
      });
  }

  render() {
    let passwordField = <input
      className="form__input"
      type="password"
      autoComplete="off"
      placeholder="Password"
      value={this.state.data.password}
      onChange={this.handlePasswordChange}
      required
    />;
    return (
      <div>
        <div className="grid">
          <div id="getemail" className="centered grid__col--5" >
            {(this.state.data.hasErrors) ? <div className="ui negative message">
              {this.state.data.errorMessage}
            </div> : ''}

            {(this.state.data.success) ? <div className="ui positive message">
              User information was updated successfully!
            </div> : ''}
            <form className="form" onSubmit={this.handleUpdateSubmit}>
              <div className="form__group">
                <label className="form__label--hidden" htmlFor="email">Email address:</label>
                <input
                  className="form__input"
                  type="text"
                  placeholder="First Name"
                  value={this.state.data.firstName}
                  onChange={this.handleFirstNameChange}
                  required
                />
                <input
                  className="form__input"
                  type="text"
                  placeholder="Last Name"
                  value={this.state.data.lastName}
                  onChange={this.handleLastNameChange}
                  required
                />
                <input
                  className="form__input"
                  type="email"
                  placeholder="Email address"
                  value={this.state.data.email}
                  onChange={this.handleEmailChange}
                  required
                />
                <input
                  className="form__input"
                  placeholder="Company Name"
                  value={this.state.data.company}
                  onChange={this.handleCompanyChange}

                />
                <Dropdown placeholder='Select your Country' clearable selection
                  onChange={this.handleCountryChange} fluid
                  className="form-input"
                  value={this.state.data.countryCode}
                  options={this.state.countries} />
                {this.state.data.countryCode === "" || this.state.data.countryCode === null ? null : <div>
                  <Input label={'+' + this.state.data.phoneCode}
                    placeholder='Phone' required={this.state.data.countryCode !== null}
                    value={this.state.data.phone} onChange={this.onPhoneNumberChange} />
                </div>}
                <h4 className="ui horizontal inverted divider"> </h4>
                <label className="link">
                  <input
                    style={{ display: "inline-block" }}
                    type="checkbox"
                    name="enablePassword"
                    checked={this.state.data.enablePassword}
                    onChange={this.handleEnablePasswordChange}
                  /> Change Password?
                </label>
                {this.state.data.enablePassword ? <div>
                  <Popup
                    trigger={passwordField}
                    open={this.state.data.enablePassword}
                    content={this.state.validations !== null ?
                      this.state.validations.map((x, i) => (<div className="align left" key={i}>
                        {x.status ? <i className="check circle icon teal"></i> : <i className="minus circle icon red"></i>}
                        {x.message} <br /></div>)) : null}
                    header='Password Policy'
                    position='right center'
                    size='small'
                  />
                  {this.state.validations.filter(x => !x.status).length === 0 ?
                    <input
                      className="form__input"
                      disabled={!this.state.data.enablePassword}
                      type="password"
                      autoComplete="off"
                      placeholder="Confirm Password"
                      value={this.state.data.confirmPassword}
                      onChange={this.handleConfirmPasswordChange}
                      required
                    /> : null}</div> : null}
                <input className="ui blue button huge fluid" type="submit" value="Update" />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default MyAccount;
