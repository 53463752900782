import React, { Component } from 'react'

export default class IMRowHeader extends Component {
    render() {
        const self = this;
        return <thead>
            <tr key="header">
                {self.props.columns.map((x, i) => {
                    let columnSortClass = x.identificator && self.props.columnSort === x.identificator ? "sorted" : "";

                    return <th key={"header" + i}
                        className={`${x.colWidth} wide ${x.sortable && x.identificator ? 'sortable' : ''} ${columnSortClass}`}
                        onClick={() => self.props.sortColumn(x.identificator)} >{x.header}
                        {columnSortClass ? (self.props.directionSort === "asc" ? <i class="fas fa-sort-down right"></i>
                            : <i class="fas fa-sort-up right"></i>)
                            : ''
                        }
                    </th>;
                })}
            </tr>
            <tr key="filter">
                {self.props.columns.map((x, i) => <th key={"filter" + i} className={`${x.colWidth} wide`} >{!x.filterRender ? null :
                    x.filterRender((value) => self.props.onFilterChanged(x.identificator, value))}</th>)}
            </tr>
        </thead>

    }
}
