import { Grid, Button, Input } from 'semantic-ui-react';

import _ from 'underscore';
import React from 'react';
import IMComponent from '../Components/IMComponent';
import { Link } from 'react-router-dom';

export default class UsersGrid extends IMComponent {
    constructor(props) {
        super(props, {});
        this.state = { queryName: '' };
    }

    renderHeader = () => {
        const { queryName } = this.state;
        return [
            <Grid.Row key="header" >
                <Grid.Column width={1} className="panel__text--title"></Grid.Column>
                <Grid.Column width={7} className="panel__text--title">NAME</Grid.Column>
                <Grid.Column width={2} className="panel__text--title"></Grid.Column>
            </Grid.Row>,
            <Grid.Row key="filter">
                <Grid.Column width={1} ></Grid.Column>
                <Grid.Column width={7} >
                    <Input
                        name="queryName"
                        type="text"
                        className="form-input"
                        fluid
                        value={queryName}
                        onChange={this.onInputChanged.bind(this)} />
                </Grid.Column>
                <Grid.Column width={2} className="middle aligned" >
                    <a className="ui tag label blue">Filter</a>
                </Grid.Column>
            </Grid.Row>];
    }

    renderData = (data) => {
        return data.map((item, index) =>
            <Grid.Row key={`${index}${Math.random()}`}>
                <Grid.Column width={1} key={index + "a"}>
                    <img className="user__icon" src="img/user.png" alt="license icon" />
                </Grid.Column>
                <Grid.Column width={7} key={index + "c"}>
                    <p>{`${item.firstName != null ? item.firstName : "[no first name]"} ${item.lastName != null ? item.lastName : "[no last name]"}`}<br/>{item.email}</p>
                </Grid.Column>
                <Grid.Column width={2} key={index + "e"}>

                <button type="button" className={(item.administrator ? 'blue' : 'gray') + " ui button"}
                    id={item.id} onClick={this.props.toggleAdministratorPermission}
                    > {item.administrator ?"IM Super Administrator":"Standard User"}</button>                </Grid.Column>
                <Grid.Column width={2} key={index + "e"}>
                    <Link className="blue ui button" to={`/user/Edit/${item.id}`} style={{ height: "35px" }}>Edit</Link>
                                 </Grid.Column>
               
            </Grid.Row>);

    }

    validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    onInputChanged(e) {
        this.setState({ queryName: e.target.value });
        var val = e.target.value; 
        //valida special chars
        var format = /[!#$%^&*()+=\[\]{};:"\\|,<>\/?]+/;
        if (format.test(val)) {
            if (!this.validateEmail(val)) {
                alert("Please specify valid characters for names or valid email formats");
                return;
            }

        }
        this.props.onHandleFilterChange(e.target.value);
    }

    render() {
        const data = this.props.data ? this.props.data : [];
        return <Grid>{[this.renderHeader(), this.renderData(data)]}</Grid>;
    }
}
