import React from 'react';

import axios from 'axios';


class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      email: "",
      sucess: false
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleForgotPasswordSubmit = this.handleForgotPasswordSubmit.bind(this);
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handleForgotPasswordSubmit(e) {
    e.preventDefault();
    this.setState({ sending: true });
    const self = this;
    return axios.post('/api/ForgotPassword', { Email: this.state.email })
      .then(function (login) {

        if (login) {
          self.setState({ email: '', sucess: true })
          self.setState({ sending: false })
        }
      })
      .catch(function (e) {
        console.error(e.status, e.toString());
        self.setState({ sending: false })
      });
  }

  render() {
    let success;
    let form;
    success = this.state.sucess ? <div id="getemail" className="centered grid__col--5 panel__inverse--padded padding__extra">
      <h1 className="headline-primary--grouped--inverse">Email Sent <i className="fas fa-check-circle blue-phrase right"></i></h1>
      <div className="">
        <p className="form__text">An email with instructions on how to reset your password has been sent. Check your spam or junk folder if you don’t see the email in your inbox.</p>
        <p className="form__text">If you no longer have access to this email account, please <a className="link blue-phrase" href="mailto:help@informationmapping.com">contact us.</a></p>
        <a className="ui inverted blue button fluid huge" href="/">Return to login</a>
      </div>
    </div> : '';
    form = !this.state.sucess ? <div id="getemail" className="centered grid__col--5 panel__inverse--padded">
      <h1 className="headline-primary--centered--inverse">Having trouble signing in?</h1>
      <p className="panel__text--center">Enter your email address.</p>
      <form className="form" onSubmit={this.handleForgotPasswordSubmit}>
        <div className="form__group">
          <label className="form__label--hidden" htmlFor="email">Email address:</label>
          <input
            className="form__input"
            type="email"
            placeholder="name@example.com"
            value={this.state.email}
            onChange={this.handleEmailChange}
            required
          />
          <p className="form__text">We will send you an email with instructions on how to reset your password.</p>
          {(this.state.sending) ? <button className="form__btn" ><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></button> : <input className="ui blue fluid button huge" type="submit" value="Email me" />}
        </div>
      </form>
    </div> : '';

    return (
      <div>
        <div className="grid">
          <header className="grid__col--12 panel--padded--centered" role="banner">
            <a className="site-logo left" href="/">
              <b className="srt">Information Mapping Logo</b>
            </a>
            <h1 className="headline-primary--grouped right"><a className="site-logo-trial" href="/">FS Pro Console</a></h1>
          </header>
        </div>
        <div className="grid padded relative">
          {success}
          {form}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
