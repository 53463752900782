import { combineReducers, createStore } from "redux";

import { applicationReducer } from "./applicationReducers";


const reducers = combineReducers({
    application: applicationReducer
});

export const store = createStore(reducers, /* preloadedState, */
    0 + window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;