import React from 'react'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

function IMToastr(props) {

    if (props.success) {

        toast({
            type: "success",
            icon: "check",
            title: props.success.title,
            description: props.success.message,
            animation: 'bounce',
            time: 5000,
            onClick: function () { console.log(`toast was clicked by user. title: `) },
            onClose: function () { console.log(`toast was closed. title: `) }
        })
    } else if (props.error) {

        toast({
            type: "error",
            icon: "times",
            title: props.error.title,
            description: props.error.message,
            animation: 'bounce',
            time: 5000,
            onClick: () => console.log(`toast was clicked by user. title: `),
            onClose: () => console.log(`toast was closed. title: `)
        }
        );
    }

    return (
        <SemanticToastContainer position="bottom-right" />
    )
}

const mapStateToProps = function (state) {
    return {
        success: state.application.toast.success,
        error: state.application.toast.error,
    }
}

export default connect(mapStateToProps)(IMToastr);