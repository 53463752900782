import React from 'react';

import _ from "underscore";

import { Button, Grid, Icon, Input, Segment } from 'semantic-ui-react';

import IMComponent from '../Components/IMComponent';


export default class ProductSkus extends IMComponent {
    constructor(props) {
        super(props);
        this.state = { newSku: "", newMonths: 0, newYears: 0, newQuantity: 1, skuIdFilter: "" }
        this.clickColumnSkuHandler = this.clickColumnSkuHandler.bind(this);
        this.skuFilterChangedHandler = this.skuFilterChangedHandler.bind(this);
    }

    onDeleteSku = (sku) => {
        this.props.onDeleteSku(sku);
    }

    onAddSku = () => {
        if (this.state.newSku && this.state.newSku != "") {
            let skus = this.props.skus;
            if (skus.filter(x => x.skuId == this.state.newSku).length == 0) {
                if (!isNaN(this.state.newMonths) && !isNaN(this.state.newQuantity) && !isNaN(this.state.newYears)) {
                    let sku = { skuId: this.state.newSku, months: parseInt(this.state.newMonths), years: parseInt(this.state.newYears), quantity: parseInt(this.state.newQuantity) };
                    this.props.onAddSku(sku);
                    this.cleanFields();
                } else {
                    alert("You must insert a valid number");
                }

            } else {
                alert('Sku already exists');
            }

        }

    }

    cleanFields = () => {
        this.setState({ newSku: "", newMonths: 0, newYears: 0, newQuantity: 1 });
    }

    onChangeNewSku = (e) => {
        switch (e.target.name) {
            case "skuText":
                this.setState({ newSku: e.target.value });
                break;
            case "monthsText":
                this.setState({ newMonths: e.target.value != "" ? e.target.value : 0 });
                break;
            case "yearsText":
                this.setState({ newYears: e.target.value != "" ? e.target.value : 0 });
                break;
            case "quantityText":
                this.setState({ newQuantity: e.target.value != "" ? e.target.value : 1 });
                break;
            default: break;
        }
    }
    clickColumnSkuHandler = function () {

        const { orderSku } = this.state;
        this.setState({
            orderSku: {
                dir: orderSku && orderSku.dir === "asc" ? "desc" : "asc"
            }
        })
    }
    skuFilterChangedHandler = function (e, { name, value }) {
        this.setState({ [`${name}Filter`]: value });
    }

    renderHeader = () => {
        const { orderSku, skuIdFilter } = this.state;
        const emailOrderIcon = !orderSku ? "arrows alternate vertical" : `arrow ${(orderSku.dir === "asc" ? "down" : "up")}`;

        return [
            <Grid.Row key="header">
                <Grid.Column width={3}>
                    <Input
                        name="skuId"
                        type="text"
                        className="form-input"
                        fluid
                        value={skuIdFilter}
                        onChange={this.skuFilterChangedHandler} />
                </Grid.Column>
                <Grid.Column width={3} >
                </Grid.Column>
                <Grid.Column width={3} >
                </Grid.Column>
                <Grid.Column width={3} >
                </Grid.Column>
                <Grid.Column width={3} >
                </Grid.Column>

            </Grid.Row>,
            <Grid.Row key="header">
                <Grid.Column width={3}>
                    <b><div onClick={this.clickColumnSkuHandler}>SKU<Icon name={`${emailOrderIcon}`} /></div></b>
                </Grid.Column>
                <Grid.Column width={3} >
                    <b>Months</b>
                </Grid.Column>
                <Grid.Column width={3} >
                    <b>Years</b>
                </Grid.Column>
                <Grid.Column width={3} >
                    <b>Users</b>
                </Grid.Column>
                <Grid.Column width={3} >
                    <b>Actions</b>
                </Grid.Column>

            </Grid.Row>];

    }

    renderData = (data) => {
        return data.map((item) =>
            <Grid.Row>
                <Grid.Column width={3} >
                    <p>{item.skuId}</p>
                </Grid.Column>
                <Grid.Column width={3} >
                    <p>{item.months}</p>
                </Grid.Column>
                <Grid.Column width={3} >
                    <p>{item.years}</p>
                </Grid.Column>
                <Grid.Column width={3} >
                    <p>{item.quantity}</p>
                </Grid.Column>
                <Grid.Column width={3} >
                    <button type="button" className="ui button red btn" onClick={() => { this.onDeleteSku(item) }}> <i className="fas fa-trash-alt"></i>  Delete</button>
                </Grid.Column>
            </Grid.Row>);
    }

    renderNewSkuLine = () => {
        return (<Grid.Row>
            <Grid.Column width={3} >
                <Input
                    name="skuText"
                    type="text"
                    className="form-input"
                    fluid
                    value={this.state.newSku}
                    onChange={this.onChangeNewSku} />
            </Grid.Column>
            <Grid.Column width={3} >
                <Input
                    name="monthsText"
                    type="text"
                    className="form-input"
                    fluid
                    value={this.state.newMonths}
                    onChange={this.onChangeNewSku} />
            </Grid.Column>
            <Grid.Column width={3} >
                <Input
                    name="yearsText"
                    type="text"
                    className="form-input"
                    fluid
                    value={this.state.newYears}
                    onChange={this.onChangeNewSku} />
            </Grid.Column>
            <Grid.Column width={3} >
                <Input
                    name="quantityText"
                    type="text"
                    className="form-input"
                    fluid
                    value={this.state.newQuantity}
                    onChange={this.onChangeNewSku} />
            </Grid.Column>
            <Grid.Column width={3} >
                <Button fluid primary size="big" content="ADD" onClick={this.onAddSku}
                />
            </Grid.Column>
        </Grid.Row>);
    }

    render() {
        const { orderSku, skuIdFilter } = this.state;
        let data = this.props.skus ? this.props.skus.filter(function (sku) {
            return sku.skuId.includes(skuIdFilter);
        }) : [];

        const orderedData = !orderSku ? data : data.sort(function (a, b) {
            return orderSku.dir === 'asc' ? a.skuId > b.skuId : b.skuId > a.skuId;
        })
        return <Segment style={{ overflow: 'auto', maxHeight: 500 }}>
            <Grid style={{ width: "100%" }}>{[this.renderHeader(), this.renderData(orderedData), this.renderNewSkuLine()]}</Grid>
        </Segment>;

    }
}