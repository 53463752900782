import React from 'react';


class ErrorMessage extends React.Component{
  
  render(){
    return (
      <div className="validation-text">
        <strong className="astreik">*</strong><span>{this.props.errorMessage}</span>
      </div>
    );
  }
}

export default ErrorMessage;
