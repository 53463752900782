import React from 'react';
import ErrorMessage from './ErrorMessage';



class Textbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      value: null,
      errorMessage: 'Input is not valid',
    };
    if ((typeof props.validate) !== "function")
      this.validate = (x) => true;
    else
      this.validate = props.validate;
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  validation=(value, valid)=>{
    if (typeof valid === 'undefined') {
      valid = true;
    }
    let message = '';
    let showError = false;
    if (!valid) {
      message = this.props.errorMessage;
      valid = false;
      showError = true;
    }

    this.setState({
      value: value,
      valid: valid,
      errorMessage: message,
      showError: showError
    });
  }

  handleChange(e) {
    this.validation(e.target.value);
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  handleKeyPress(e) {
    if (this.props.onKeyPress !== undefined)
      this.props.onKeyPress(e);
  }

  handleBlur(e) {
    let valid = true;
    valid = this.validate(e.target.value);
    this.validation(e.target.value, valid);
  }

  render() {
    return (
      <div className={this.props.className}>{
        this.props.type === 'text' ?
          <input type={this.props.type}
            name={this.props.name}
            className="item-required form__input--small"
            placeholder={this.props.text}
            value={this.props.value || ''}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress} />
          : this.props.type === 'number' ?
            <input type={this.props.type}
              name={this.props.name}
              className="item-required form__input--small"
              min={this.props.min}
              step={this.props.step}
              value={this.props.value || ''}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress} />
            : null
      }
        {this.state.showError ? <ErrorMessage errorMessage={this.props.errorMessage} /> : null}
      </div>
    );
  }
}

export default Textbox;
