import Axios from "axios";

import AuthenticationInformation from "./AuthenticationInformation";

export class AuthenticationService {

    authenticate(email, password) {
        const urlAPI = '/api/authenticate';
        const self = this;
        return Axios.post(urlAPI, { email, password }, { params: { version: Math.random() } })
            .then(function () {
                return self.getCurrentUser();
            })
            .catch(function (ex) {
                console.log(ex);

            });
    }
    getCurrentUser() {
        const urlAPI = 'api/currentUser';
        return Axios.get(urlAPI, { params: { version: Math.random() } })
            .then(function (response) {
                AuthenticationInformation.instance.userInformation = response.data;
            })
            .catch(function (ex) {

                console.error(ex);
            })
    }
}