import moment from "moment";

import _ from "underscore";

import { specialCharsAndNumbers, upperCaseLetters, email as emailPattern } from "./PatternHelper";

export function isEmailValid(email) {
  return !!email && email.match(emailPattern);
}

export function getPasswordValidation(password, passwordSetting = null) {
  if (passwordSetting === null)
    passwordSetting = {
      minimunChars: 8,
      uppercaseChars: 1,
      specialChars: 1,
      expirationMonths: 1,
      twoFA: false
    };
  var meetLengthPolicy = password.length >= passwordSetting.minimunChars;
  var notMeetSpecialCharRegEx = specialCharsAndNumbers;
  var meetSpecialCharPolicy = password.match(notMeetSpecialCharRegEx);
  var meetUpperPolicy = password.match(upperCaseLetters);
  var validations = [];

  validations.push({ status: meetLengthPolicy, message: `At least ${passwordSetting.minimunChars} characters` });
  validations.push({ status: meetSpecialCharPolicy !== null && meetSpecialCharPolicy.length >= passwordSetting.specialChars, message: `At least ${passwordSetting.specialChars} special character or number` });
  validations.push({ status: meetUpperPolicy !== null && meetUpperPolicy.length >= passwordSetting.uppercaseChars, message: `At least ${passwordSetting.uppercaseChars} upper case character` });

  return validations;
}

export const messages = {
  errors: {
        invalidEmail: "Email address is invalid. Please enter a valid address.",
        firstNameRequired: "First Name is a required field",
        lastNameRequired: "Last Name is a required field"
  },
  success: {
    usageWasAddedSucessfully: "License Usage was added successfully."
  }
}

export function getCurrentDate() {
    let today = new Date();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (mm < 10) {
        mm = '0' + mm;
    }
    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    return mm + '/' + dd + '/' + yyyy;
}

export function IsValidDate(ddmmyyyy) {
  if (ddmmyyyy.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/) === null)
    return false;
  //let mmddyyyy = ConvertddmmyyyyTommddyyy(ddmmyyyy);
    return validate(ddmmyyyy);
}

export function ConvertddmmyyyyTommddyyy(ddmmyyyy) {
  let str = ddmmyyyy.split('/');
  let mmddyyyy = '';
  mmddyyyy = mmddyyyy.concat(str[1], '/', str[0], '/', str[2]);
  return mmddyyyy;
}

export function validate(mmddyyyy) {
  var date = new Date(mmddyyyy);
  mmddyyyy = mmddyyyy.split('/');
  return date.getMonth() + 1 === +mmddyyyy[0] &&
    date.getDate() === +mmddyyyy[1] &&
    date.getFullYear() === +mmddyyyy[2];
}

//export function getDate(ddmmyyyy) {
//  var t = ddmmyyyy.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
//  let d = parseInt(t[1]), m = parseInt(t[2], 10), y = parseInt(t[3], 10);
//  return new Date(y, m, d);
//}

// Returns the month (0 - 11) in the specified date according to local time.
// normalizeMonth argument allows to normalize passed month (current value - 1)
export function getDate(mmddyyyy, normalizeMonth = false) {
    var t = mmddyyyy.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
    let m = parseInt(t[1], 10), d = parseInt(t[2], 10), y = parseInt(t[3], 10);

    if (normalizeMonth) {
        m = m - 1;
    }

    return new Date(y, m, d);
}

export function isFieldEmpty(str) {
  str = str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
  return str === '' ? true : false;
}

export function convertDateTommddyyyy(date) {
    if (typeof date == 'undefined' || isFieldEmpty(date)) return '';
    let dt = new Date(date);
    let mm = dt.getMonth() + 1;
    let dd = dt.getDate();
    let yyyy = dt.getFullYear();

    if (mm < 10) {
        mm = '0' + mm;
    }
    if (dd < 10) {
        dd = '0' + dd;
    }
    return mm + '/' + dd + '/' + yyyy;
}

export function convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  let d = stringToDate(inputFormat);
  let month = [];
  month[1] = 'JAN';
  month[2] = 'FEB';
  month[3] = 'MAR';
  month[4] = 'APR';
  month[5] = 'MAY';
  month[6] = 'JUN';
  month[7] = 'JUL';
  month[8] = 'AUG';
  month[9] = 'SEPT';
  month[10] = 'OCT';
  month[11] = 'NOV';
  month[12] = 'DEC';
  
  return [pad(d.getDate()), pad(month[d.getMonth() + 1]), d.getFullYear()].join(' ');
}

export function stringToDate(stringValue) {
  if (!stringValue)
    return moment().toDate();
  return moment(stringValue, dateFormat).toDate();
}
export function dateToString(date) {
  return moment(date).format(dateFormat);
}
export const dateFormat = "MM/DD/YYYY";

export function activityDate(inputFormat) {
  let date = new Date(inputFormat);
  let pastDate = new Date();
  return (date > pastDate.setDate(pastDate.getDate() - 90));
}

String.prototype.format = function () {
  let a = this;
  for (let k in arguments) {
    a = a.replace("{" + k + "}", arguments[k])
  }
  return a
}

export const errorMessages = {
  general: {
    incorrectFormat: "{0} field does not have correct format."
  }
}

export const isNullOrEmpty = (val) => {

  return (_.isUndefined(val) || val === "" || _.isNull(val));
}

export const getCookieByName = (name) => {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name.replace("@", "%40") + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();      
}