import React, { Component, Fragment } from 'react'
import { Popup } from 'semantic-ui-react';


export default class IMComponent extends Component {

    constructor(props, model = {}) {
        super(props);
        this.state = {
            model: model,
            errors: {}
        }
        this.model = { ...model };
        this.showToastError=this.showToastError.bind(this);
        this.showToastSuccess=this.showToastSuccess.bind(this);
        this.resetModel=this.resetModel.bind(this);
    }

    showToastError = function (error) {
        this.props.errorMessage(error);
    }

    showToastSuccess = function (success) {
        this.props.successMessage(success);
    }

    resetModel = function () {
        this.setState({ model: { ...this.model } });
    }

    onModelPropertyChange = ({ target }) => {
        let model = this.state.model;
        model[target.name] = target.value;
        this.setState({
            model
        });
        this.setState({
            validModel: this.onModelValidation()
        })
    }

    addError = (name, message) => {
        let errors = this.state.errors;
        errors[name] = message;
        this.setState({ errors });
    }

    removeError = ({ target }) => {
        let errors = this.state.errors;
        errors[target.name] = null;
        this.setState({ errors });
    }
    clearErrors = () => {
        this.setState({ errors: {} });
    }

    onModelValidation = () => {
        return true;
    }

    onPropertyChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value });
        this.afterPropertyChanged();
    }
    afterPropertyChanged = () => {

    }
}



export function IMErrorField({ children, error }) {

    return (
        <Fragment>
            <Popup
                color="red"
                className="red error"
                trigger={children}
                content={error}
                position='right center'
                open={!!error} />
        </Fragment>
    );
}