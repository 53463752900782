
import React from 'react';

import Axios from 'axios';

import { Link } from 'react-router-dom';

import { Form, Input, Label, Popup } from 'semantic-ui-react';

import { onUserChanged } from '../Index';
import CenteredMidLayout from '../Components/CenteredMidLayout';
import IMComponent, { IMErrorField } from '../Components/IMComponent';
import { showLoading, hideLoading } from '../Components/ModalComponent';
import AuthenticationInformation from '../Utilities/AuthenticationInformation';
import { countryOptions } from '../../components/Semantic-ui-options';
import { isEmailValid, errorMessages, getPasswordValidation, isNullOrEmpty } from '../../components/utility/Helper';
import { connect } from 'react-redux';
import applicationActionCreator, { actions } from '../../redux/actions/applicationActions';
import { bindActionCreators } from 'redux';

function AccountCenteredMidLayout({ children: Children }) {
    return (
        <div className="grid" style={{ marginLeft: "10px" }}>
            <div id="getemail" className="grid__col--5" style={{ width: "65%", marginLeft: "0px", float: "left" }}>
                {Children}
            </div>
        </div>
    )
}

class MyAccount extends IMComponent {
    constructor(props) {
        super(props, { firstName: '', phone: '', password: '', repassword: '', lastName: '', email: '', company: '', countryCode: '', phoneCode: '' });
        this.state = {
            countries: [],
            isManager: AuthenticationInformation.instance.isManager,
            ...this.state
        };
        props.showLoading('LOAD_MYACCOUNT');
        this.onModelValidation = this.onModelValidation.bind(this);
        this.loadCountriesAvailable = this.loadCountriesAvailable.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.loadPasswordSetting();
    }

    loadCountriesAvailable = function () {
        const self = this;
        Axios.get('/api/CountriesPhone')
            .then(function (countries) {

                const onlyShortNames = countries.data.map(x => x.shortName);
                const filteredCountries = countryOptions.filter(x => onlyShortNames.includes(x.key.toUpperCase())).map(x => {
                    const country = countries.data.find(y => y.shortName === x.key.toUpperCase());
                    x.code = country.code;
                    return x;
                });

                let currentCountryCode = filteredCountries.find(x => x.key === self.state.model.countryCode);
                self.setState({
                    countries: filteredCountries,
                    model: {
                        ...self.state.model,
                        phoneCode: currentCountryCode !== undefined ? currentCountryCode.code : "",
                    }
                });
            })
    }

    onPasswordEnabled = () => {
        const { passwordSetting } = this.state;
        this.setState({ passwordValidations: getPasswordValidation("", passwordSetting), validModel: false });
    }

    onPasswordDisabled = () => {
        const { model } = this.state;
        this.setState({ model: { ...model, password: "", repassword: "" } });
    }

    onSubmitHandler = function () {
        const self = this;
        this.props.showLoading('SUBMIT_MYACCOUNT_CHANGES');
        return Axios.put('api/User/Update', this.state.model)
            .then(function () {
                self.props.history.push('/Licenses');
                self.showToastSuccess({ title: "Update", message: "User information was successfully updated.Please check your mail for confirmation of email changing." });
            })
            .catch(function (ex) {
                self.showToastError({ title: "", message: ex.response.data });
            })
            .then(function () {
                self.props.hideLoading('SUBMIT_MYACCOUNT_CHANGES');
            })
    }

    loadUserData = function () {
        const self = this;
        return Axios.get('/api/User', { params: { version: Math.random() } })
            .then(function (response) { return response.data })
            .then(function (userInformation) {
                self.setState({
                    isManager: userInformation.isManager,
                    model: {
                        firstName: userInformation.firstName,
                        company: userInformation.company || '',
                        lastName: userInformation.lastName,
                        email: userInformation.email,
                        createdAt: userInformation.createdAt,
                        phone: userInformation.phone,
                        countryCode: userInformation.countryCode,
                        modifiedAt: userInformation.modifiedAt
                    }
                });

            })
            .catch(function (ex) {
                console.log(ex);
            })
            .then(function () {
                self.setState({
                    validModel: self.onModelValidation()
                });
                return self.loadCountriesAvailable();
            })
    }

    componentDidMount = function () {
        const self = this;
        this.loadUserData()
            .then(function () {
                return self.onModelValidation();
            })
            .then(function () {
                self.props.hideLoading('LOAD_MYACCOUNT');
            })
    }

    onModelValidation = () => {
        const { phone, password, repassword, email, countryCode } = this.state.model;
        const { passwordEnabled, passwordSetting } = this.state;
        let isValid = true;
        this.clearErrors();
        if (!isEmailValid(email)) {
            this.addError("email", errorMessages.general.incorrectFormat.format("email"));
            isValid = false;
        }

        if (passwordEnabled) {
            let passwordValidations = getPasswordValidation(password ? password : "")
            if (passwordValidations.filter(x => !x.status).length > 0 || password !== repassword)
                isValid = false;
            this.setState({ passwordValidations: getPasswordValidation(password ? password : "", passwordSetting) });
        }
        return isValid;
    }

    loadPasswordSetting() {
        this.props.showLoading('LOAD_PASSWORDSETTINGS')
        const self = this;
        return Axios.get(`/api/PasswordSettings/CurrentUser`)
            .then(function (passwordSetting) {
                self.setState({ passwordSetting: passwordSetting.data });

            })
            .then(function () {
                self.props.hideLoading('LOAD_PASSWORDSETTINGS');
            })
    }
    handleCountryChange = () => {
        let country = { code: null, value: "" };
        if (this.state.model.countryCode !== "")
            country = countryOptions.find(x => x.key === this.state.model.countryCode);

        this.onModelPropertyChange({ target: { name: "phoneCode", value: country.code } });
    }

    componentWillUpdate = () => {

        const { phone, password, repassword, countryCode } = this.state.model
        const { passwordEnabled } = this.state;
        const phoneEnabled = !!countryCode;

        if (!phoneEnabled && !!phone)
            this.onModelPropertyChange({ target: { name: "phone", value: '' } });
        if (!passwordEnabled && (password || repassword)) {
            this.onModelPropertyChange({ target: { name: "password", value: '' } });
            this.onModelPropertyChange({ target: { name: "repassword", value: '' } });
        }

    }

    render() {

        const { firstName, phone, password, repassword, lastName, email, company, countryCode, phoneCode } = this.state.model;
        const { countries, validModel, passwordValidations, passwordEnabled, isManager } = this.state;
        const { email: errorEmail } = this.state.errors;
        const classPassword = passwordEnabled ? "im_text_field" : "hidden";
        const phoneEnabled = !!countryCode;
        const errorCountryCode = !!countryCode ? '' : 'Please select your Country';

        return (
            <div>
                <div className="ui grid">

                    <div className="eight wide column">
                        <h1 className="headline-primary ">My Profile</h1>
                    </div>
                    <div className="eight wide column">
                        <Link to='/Licenses' className="pull-right float right" >
                            <svg className="special__icon">
                                <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
                            </svg>
                        </Link>
                    </div>
                </div>
                <AccountCenteredMidLayout>
                    <Form size="huge" onSubmit={this.onSubmitHandler} className="formAccount">
                        <Form.Input className='ui im_text_field' placeholder='First Name' name="firstName"
                            value={firstName} required label="First Name"
                            onChange={this.onModelPropertyChange}></Form.Input>

                        <Form.Input className='im_text_field' placeholder='Last Name' name="lastName"
                            value={lastName} required label="Last Name"
                            onChange={this.onModelPropertyChange}></Form.Input>
                        <IMErrorField error={errorEmail}>
                            <Form.Input className='im_text_field' placeholder='some@email.com' name="email"
                                type="email" label="Email Address"
                                value={email} required
                                onChange={this.onModelPropertyChange}></Form.Input>
                        </IMErrorField>

                        {this.state.isManager ?
                            <Form.Input className='im_text_field' placeholder='Company  Name' name="company"
                                value={company} label="Company Name"
                                onChange={this.onModelPropertyChange}></Form.Input> :
                            <Form.Field>
                                <Label size="big" color="blue" className="fluid">{company}</Label>
                            </Form.Field>}

                        <IMErrorField error={errorCountryCode} position='right center'>
                            <Form.Select required className='im_text_field' options={countries} clearable
                                label="Country"
                                name="countryCode" value={countryCode} placeholder='Select your Country' selection
                                onChange={(e, d) => { this.onModelPropertyChange({ target: d }); this.handleCountryChange(); }}>
                            </Form.Select>
                        </IMErrorField>

                        {phoneEnabled ?
                            <Form.Field>
                                <label>Phone Number</label>
                                <Input label={"+" + phoneCode} name="phone" className='im_text_field no-spin'
                                    placeholder='Phone' type="number"
                                    value={phone} onChange={this.onModelPropertyChange} />
                            </Form.Field> : null}

                        <Form.Checkbox label="Change Your Password" name="passwordEnabled"
                            onClick={() => (
                                (passwordEnabled ? this.onPasswordDisabled() : this.onPasswordEnabled()) ||
                                this.onPropertyChange({ target: { name: "passwordEnabled", value: !passwordEnabled } })
                            )}
                            checked={passwordEnabled} ></Form.Checkbox>
                        {passwordEnabled ?
                            (<Form.Field>
                                <Popup
                                    trigger={
                                        <Form.Input className={classPassword + " im_text_field"} placeholder='Type your new password' fluid name="password" required={passwordEnabled}
                                            type="password" autoComplete="off" label="Type your new password"
                                            value={password} onChange={this.onModelPropertyChange}></Form.Input>
                                    }
                                    open={passwordValidations != null}
                                    content={passwordValidations != null ? passwordValidations.map((x, i) => (<div style={{
                                        textAlign: "left", width: "250px"
                                    }} key={i} >
                                        {x.status ? <i className="check circle icon teal"></i> : <i className="minus circle icon red"></i>}
                                        {x.message} <br /></div>)) : null}
                                    header='Password Policy'
                                    position='right center'
                                    size='small'
                                />
                            </Form.Field>) : null}

                        <Form.Input placeholder='Retype your new password' name="repassword"
                            value={repassword} required={passwordEnabled} label="Retype your new password"
                            type="password" autoComplete="off" className={classPassword}
                            onChange={this.onModelPropertyChange}></Form.Input>
                        <label style={{ fontSize: "0.7em", paddingBottom: "5px", fontFamily: "Lato, Helvetica Neue, Helvetica, Arial, sans-serif" }}>(*) Required Fields.</label><br />

                        <Form.Button content="Save" fluid primary size="huge" disabled={!validModel || !(!!countryCode)} />
                        {isManager ?
                            <Link to='/user/password-setting' onClick={this.getPasswordSettings} className="ui button fluid primary huge" title="This will set the minimum password length and password strength forthe passwords for all the users in this license. ">
                                Set Password Policy
                            </Link> : null}
                    </Form>
                </AccountCenteredMidLayout>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) },
        errorMessage: function (message) { return dispatch(applicationActionCreator.notification.error(message)) },
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) }
    }
}

export default connect(null, mapDispatchToProps)(MyAccount);