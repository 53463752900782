import React from 'react'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string'

const defaultUrlHome = "https://informationmapping.com";

function Logout(props) {
    const provider = props.match.params.provider
    const { ["url-home"]: urlHome = defaultUrlHome, kind: title = "Logout", message } = queryString.parse(props.location.search);

    let content = <>You are logged out from <b>{provider}</b></>;

    if (message) {
        content = message
    }

    if (urlHome) {
        setTimeout(function () {
            window.location.href = urlHome;
        }, 5000)
    }

    return (
        <div><div className="grid">
            <header className="grid__col--12 panel--padded--centered" role="banner">
                <a className="site-logo left" href="/">
                    <b className="srt">Information Mapping Logo</b>
                </a>
                <h1 className="headline-primary--grouped right"><a className="site-logo-trial" href="/">FS Pro Console</a></h1>
            </header>
        </div>
            <div className="grid padded relative">
                <div id="getemail" className="centered grid__col--5 panel__inverse--padded padding__extra">
                    <h1 className="headline-primary--grouped--inverse" style={{ marginBottom: 0 }}>{title[0].toUpperCase() + title.substring(1)}</h1>
                    <div className="form__text">{content}.</div><br />
                    <div className="">
                        {title === "error" && <>If you see this message please contact our support team: <a className="link blue-phrase" href="mailto:support@informationmapping.com">support@informationmapping.com</a></>}<br /><br /><br />
                        {urlHome && <a className="ui inverted blue button fluid huge" href={urlHome}>Return to home</a>}
                    </div>
                </div>
            </div>
        </div>
    )
}



export default withRouter(Logout);