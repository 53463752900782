import React, { Component } from 'react'
import { Switch } from 'react-router-dom'

import { SemanticToastContainer, toast } from 'react-semantic-toasts';

import License from '../License/Index';
import LicenseUsage from '../LicenseUsage/Index';
import Product from '../Product/Index';
import SingleSignOn from '../SingleSignOn/Index';
import User from '../User/Index';

import IMNavBar from './IMNavBar';
import PropsRoute from './PropsRoute';

const buttonMenuStyle = {
    backgroundImage: 'url("/img/menu-button.png")'
}

export default class PrivateLayout extends Component {

    constructor(props) {

        super(props);
        this.state = {
            appData: {},
            menuOpen: false,
            notificationInfo: null
        }
    }

    onNavigationEvent = () => {
        this.setState({ menuOpen: false });
        document.getElementById("app-main").classList.remove('push__left');
    }

    saveStatePrivate = (value) => {
        const { appData } = this.state;
        this.setState({ appData: { ...appData, ...value } });
    }

    onClickMenuOpen = () => {
        const { menuOpen } = this.state;
        this.setState({ menuOpen: !menuOpen });
        document.getElementById("app-main").classList.toggle('push__left');
    }
    render() {
        const { menuOpen, currentLicense } = this.state;
        const { administrator } = this.props.userInformation;
        const isLicenseUsage = window.location.href.indexOf("/license-usage/") > -1 ? true : false;
        const contentStyle = isLicenseUsage === true ? { width: "100%", marginLeft: "0px", marginRight: "0px", maxWidth: "none" } : {};
        const whiteSectionStyle = isLicenseUsage === true ? { width: "99%", maxWidth: "none" } : {};
        return (
            <div className="grid main-private" style={contentStyle}>
                <div className="grid" >
                    <header className="grid__col--12 panel--padded--centered" role="banner">
                        <a className="site-logo left" href="http://www.informationmapping.com/">
                            <b className="srt">Information Mapping Logo</b>
                        </a>
                        <div className="menu">

                            <svg className="menu__icon" onClick={this.onClickMenuOpen} style={buttonMenuStyle}>

                            </svg>
                        </div>
                        <h1 className="headline-primary--grouped right site-logo-trial"><a className="site-logo-trial" href="/">FS Pro Console</a></h1>
                    </header>
                    <IMNavBar isOpen={menuOpen} isAdmin={administrator} onUserChange={this.props.onUserChanged}
                        onNavigationEvent={this.onNavigationEvent} />
                </div>
                <div className="grid">
                    <div className="centered grid__col--8">
                        <h3 style={{ marginBottom: "8px", color: "white" }} className="headline-secondary--centered">Welcome to the FS Pro Console. Here you can manage your licenses.</h3>
                    </div>

                </div>
                <div className="grid" style={whiteSectionStyle}>
                    <div className="panel__inverse">
                        <Switch>
                            <PropsRoute exact path="/license-usage/:licenseId"
                                currentLicense={currentLicense} isAdmin={administrator}
                                Component={LicenseUsage} />
                            <PropsRoute path="/license" onNavigationEvent={this.onNavigationEvent}
                                Component={License} />
                            <PropsRoute path="/user" onUserChange={this.props.onUserChange}
                                Component={User} />
                            <PropsRoute path="/single-sign-on" onUserChange={this.props.onUserChange}
                                Component={SingleSignOn} />
                            <PropsRoute path="/product" goToPage={this.props.goToPage}
                                Component={Product} />
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}