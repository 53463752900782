import React from 'react';

import axios from 'axios';

import { withRouter } from 'react-router-dom';

import { Dropdown, Button } from 'semantic-ui-react';

import Textbox from './components/Textbox';
import { IsValidDate, getDate, getCurrentDate, isFieldEmpty, stringToDate } from './utility/Helper';

class AddLicense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manager: this.props.data.Manager,
      name: this.props.data.Name,
      productId: this.props.data.productId,
      startDate: stringToDate(this.props.data.StartDate),
      endDate: stringToDate(this.props.data.EndDate),
      userCount: this.props.data.UserCount,
      emailErrorMessage: '',
      // nameErrorMessage: '',
      startdateErrorMessage: '',
      enddateErrorMessage: '',
      usercountErrorMessage: '',
      productList: [],
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    // this.validateName = this.validateName.bind(this);
    this.validateEndDate = this.validateEndDate.bind(this);
    this.validateUserCount = this.validateUserCount.bind(this);
    this.handleNameKeyPress = this.handleNameKeyPress.bind(this);
    this.handleUsercountKeyPress = this.handleUsercountKeyPress.bind(this);
    this.submitLicense = this.submitLicense.bind(this);
    this.checkRequiredField = this.checkRequiredField.bind(this);
    this.updateLicenses = this.updateLicenses.bind(this);
    this.emptyFields = this.emptyFields.bind(this);
    this.updateLicense = this.updateLicense.bind(this);
    this.loadProducts();
  }

  handleCancel() {
    this.props.onCancel();
  }

  updateProducts = (products) => {
    this.setState({ productList: products });
    if (products.length > 0)
      this.setState({ productId: products[0].productId });
  }

  loadProducts() {
    return axios.get('/api/Product')
      .then(function (response) {
        if (response) {
          this.updateProducts(response.data);
        }

      })
      .catch(function (e) {
        console.error(this.props.match.url, e.status, e.toString());
      });
  }

  checkRequiredField = () => {
    let validated = true;

    if (this.state.manager === '') {
      this.setState({
        emailErrorMessage: 'email address is required and cannot be empty'
      });
      validated = false;
    }
    // if (this.state.name === '') {
    //   this.setState({
    //     nameErrorMessage: 'Name is required and cannot be empty'
    //   });
    //   validated = false;
    // }
    if (this.state.startDate === '') {
      this.setState({
        startdateErrorMessage: 'Start date is required and cannot be empty'
      });
      validated = false;
    }
    if (this.state.endDate === '') {
      this.setState({
        enddateErrorMessage: 'End date is required and cannot be empty'
      });
      validated = false;
    }
    if (this.state.userCount === '') {
      this.setState({
        usercountErrorMessage: 'Number of license is required and cannot be empty'
      });
      validated = false;
    }
    return validated;
  }

  handleDataChange(e) {
    switch (e.target.name) {
      case 'email': this.setState({ manager: e.target.value });
        break;
      case 'name': this.setState({ name: e.target.options[e.target.selectedIndex].text, productId: (e.target.value) });
        break;
      case 'startdate': this.setState({ startDate: e.target.value });
        break;
      case 'enddate': this.setState({ endDate: e.target.value });
        break;
      case 'usercount': this.setState({ userCount: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  // validateName(name) {
  //   if (isFieldEmpty(name)) {
  //     this.setState({
  //       nameErrorMessage: 'Name is required and cannot be empty'
  //     });
  //     return false;
  //   }
  //   return true;
  // }

  validateStartDate = (date) => {
    if (isFieldEmpty(date)) {
      this.setState({
        startdateErrorMessage: 'Start date is required and cannot be empty'
      });
      return false;
    }
    if (IsValidDate(date)) {
      if (getDate(date) >= getDate(getCurrentDate())) {
        if (IsValidDate(this.state.endDate)) {
          if (getDate(date) < getDate(this.state.endDate)) {
            return true;
          }
          else {
            this.setState({
              startdateErrorMessage: 'Start date cannot be greater than end date'
            });
            return false;
          }
        }
        else {
          return true;
        }
      }
      else {
        this.setState({
          startdateErrorMessage: 'Start date cannot be less than current date'
        });
        return false;
      }
    }
    else {
      this.setState({
        startdateErrorMessage: 'Start date is not valid'
      });
      return false;
    }
  }

  validateEndDate = (date) => {
    if (isFieldEmpty(date)) {
      this.setState({
        enddateErrorMessage: 'End date is required and cannot be empty'
      });
      return false;
    }
    if (IsValidDate(date)) {
      if (getDate(date) >= getDate(getCurrentDate())) {
        if (IsValidDate(this.state.startDate)) {
          if (getDate(date) > getDate(this.state.startDate)) {
            return true;
          }
          else {
            this.setState({
              enddateErrorMessage: 'End date cannot be less than start date'
            });
            return false;
          }
        }
        else {
          return true;
        }
      }
      else {
        this.setState({
          enddateErrorMessage: 'End date cannot be less than current date'
        });
        return false;
      }
    }
    else {
      this.setState({
        enddateErrorMessage: 'End date is not valid'
      });
      return false;
    }
  }

  validateUserCount(count) {
    if (isFieldEmpty(count)) {
      this.setState({
        usercountErrorMessage: 'Number of license is required and cannot be empty'
      });
      return false;
    }
    return true;
  }

  updateLicenses(location) {
    this.props.onInsert(location);
    this.props.editmode = true;
  }

  emptyFields() {
    this.setState({
      manager: '',
      startDate: '',
      endDate: '',
      userCount: ''
    });
    this.props.editmode = true;
  }

  submitLicense() {
    if (this.checkRequiredField()) {
      const self = this;
      this.props.showLoading("SubmitLicense");
      axios.post('/api/Licenses', {
        Manager: this.state.manager,
        Name: this.state.name,
        ProductId: this.state.productId,
        StartDate: stringToDate(this.state.startDate),
        EndDate: stringToDate(this.state.endDate),
        UserCount: this.state.userCount
      })
        .then(function (response) {
          self.emptyFields();
          if (response) {
            self.updateLicenses(response.headers.location);
          }
        })
        .catch(function (e) {
          console.error(self.props.match.url, e.status, e.toString());

        })
        .then(function () {

          this.props.showLoading("SubmitLicense");
        })
    }
  }

  updateLicense() {
    if (this.checkRequiredField()) {
      const self=this;
      return axios.put('/api/Licenses', {
        Manager: this.state.manager,
        Name: this.state.name,
        ProductId: this.state.productId,
        StartDate: this.state.startDate,
        EndDate: this.state.endDate,
        UserCount: this.state.userCount
      }).then(function (response) {
        if (response) {
          self.updateLicenses(response.headers.location);
          
        }
      })
        .catch(function (e) {
          console.error(self.props.match.url, e.status, e.toString());
        });
    }
  }

  handleNameKeyPress(event) {
    let e = event || window.event;
    let key = e.keyCode || e.which;
    key = String.fromCharCode(key);
    let regex = /^[a-zA-Z0-9_\s]+$/;
    if (!regex.test(this.state.name + key)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }
  }

  handleUsercountKeyPress(event) {
    let count = typeof this.state.userCount === 'undefined' ? '' : this.state.userCount;
    let e = event || window.event;
    let key = e.keyCode || e.which;
    key = String.fromCharCode(key);
    let regex = /^[1-9][0-9]*$/;
    if (!regex.test(count + key)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }
  }

  render() {
    let labelClassName = this.props.editmode ? 'col-sm-12 col-md-12' : 'col-sm-12 col-md-12';
    let textboxClassName = this.props.editmode ? 'col-sm-12 col-md-12 form__input' : 'col-sm-12 col-md-12 form__input';
    let formClassName = this.props.editmode ? 'col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3 no__padding' : 'col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3';
    let buttonClassName = this.props.editmode ? 'col-sm-offset-4 col-sm-2' : 'col-sm-offset-4 col-sm-2';
    let addupdateButton = this.props.editmode === false ?
      <Button fluid size="huge" type="button" primary onClick={this.submitLicense}>CREATE</Button> :
      <Button fluid size="huge" type="button" primary onClick={this.updateLicense}>Update</Button>;
    const productOptions = this.state.productList.map((item) => { return { key: item.productId + '', value: item.productId, text: item.name }; });
    let { productId, startdateErrorMessage } = this.state;
    if (productId === undefined && productOptions.length > 1)
      productId = productOptions[0].value;
    return (
      <div className={formClassName}>
        <form className="form-horizontal">
          <div className="form-group">
            <label className={labelClassName}>Email</label>
            <Textbox
              type="text"
              name="email"
              text="email"
              className={textboxClassName}
              errorMessage={this.state.emailErrorMessage}
              value={this.state.manager}
              onChange={this.handleDataChange}
            />
          </div>
          <div className="form-group">
            <label className={labelClassName}>Product</label>
            <Dropdown
              placeholder='Select Product'
              fluid
              onChange={(e, d) => this.handleDataChange({ target: d })}
              selection
              className={textboxClassName}
              options={productOptions}
              name="productId"
              value={productId}
            />
          </div>
          <div className="form-group">
            <label className={labelClassName}>Start Date</label>
            <Textbox
              type="text"
              name="startdate"
              text="mm/dd/yyyy"
              className={textboxClassName}
              errorMessage={startdateErrorMessage}
              value={this.state.startDate}
              validate={this.validateStartDate}
              onChange={this.handleDataChange}
            />
          </div>
          <div className="form-group">
            <label className={labelClassName}>End Date</label>
            <Textbox
              type="text"
              name="enddate"
              text="mm/dd/yyyy"
              className={textboxClassName}
              errorMessage={this.state.enddateErrorMessage}
              value={this.state.endDate}
              validate={this.validateEndDate}
              onChange={this.handleDataChange}
            />
          </div>
          <div className="form-group">
            <label className={labelClassName}>Number of Licenses</label>
            <Textbox
              type="number"
              name="usercount"
              className={textboxClassName}
              errorMessage={this.state.usercountErrorMessage}
              min="1"
              step="1"
              value={this.state.userCount}
              validate={this.validateUserCount}
              onChange={this.handleDataChange}
              onKeyPress={this.handleUsercountKeyPress}
            />
          </div>
          <div className="form-group">
            <div className={buttonClassName}>
              {addupdateButton}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(AddLicense);
