import { actions } from "../actions/applicationActions"

const initialState = {
    currentUser: {},
    loading: {
        queue: [],
        loading: false
    },
    toast: {}
}

export const applicationReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case actions.setCurrentUser:
            return { ...state, currentUser: { ...payload } }
        case actions.hideLoading:
            return {
                ...state, loading: {
                    queue: state.loading.queue.filter(function (current) { return current !== payload }),
                    loading: false
                }
            }
        case actions.showLoading:
            return {
                ...state, loading: {
                    queue: [...state.loading.queue, payload],
                    loading: true
                }
            }
        case actions.errorNotification:
            return {
                ...state, loading: {
                    queue: [...state.loading.queue, payload],
                    loading: true
                }
            }
        case actions.notification:
            return {
                ...state, toast: payload
            }
        default:
            return state
    }
}
