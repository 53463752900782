import React from 'react';

import axios from 'axios';

import { Link } from 'react-router-dom';

import { Form, Grid, Button } from 'semantic-ui-react';

import IMComponent from '../Components/IMComponent';
import { showLoading, hideLoading } from '../Components/ModalComponent';
import { bindActionCreators } from 'redux';
import applicationActionCreator, { actions } from '../../redux/actions/applicationActions';
import { connect } from 'react-redux';

class PasswordSetting extends IMComponent {
    constructor(props) {
        super(props, {
            minimunChars: 8,
            uppercaseChars: 1,
            specialChars: 1,
            expirationMonths: 12,
            twoFA: false,
            passwordHistory: false
        });
        this.state = {
            ...this.state,
            minChars: 8,
            errorMessage: '',
            successMessage: '',
        };
        this.loadPasswordSetting();
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }
    componentDidMount() {
    }

    loadPasswordSetting = function () {
        const self = this;
        return axios.get('/api/PasswordSettings/CurrentUser')
            .then(function (response) {
                self.setState({ model: response.data });
            });
    }
    onModelValidation = () => {
        const { minimunChars, uppercaseChars, specialChars, ...model } = this.state.model;
        let minChars = parseInt(specialChars) + parseInt(uppercaseChars);

        if (minChars < 8)
            minChars = 8;
        this.setState({
            model: {
                ...model,
                specialChars: parseInt(specialChars),
                uppercaseChars: parseInt(uppercaseChars),
                minimunChars: minimunChars < minChars ? minChars : parseInt(minimunChars)
            },
            minChars
        });
        this.forceUpdate();
        return true;
    }

    onSubmitHandler = function () {
        const self = this;
        this.setState({ errorMessage: '', successMessage: '' });
        this.props.showLoading('SUBMIT_PASSWORDSETTINGS');
        return axios.post('/api/PasswordSettings', this.state.model)
            .then(function () {
                self.setState({ successMessage: 'Password settings saved successfully.' });

            })
            .catch(function (ex) {

                const errorMessage = ex.data;
                self.setState({ errorMessage });
            })
            .then(function () {
                self.props.hideLoading('SUBMIT_PASSWORDSETTINGS');
            });
    }

    render() {
        const { minimunChars, uppercaseChars, specialChars, twoFA, expirationMonths, passwordHistory } = this.state.model;
        const { errorMessage, successMessage, minChars } = this.state;
        let view = (
            <div className="grid">
                <Grid>
                    <Grid.Row columns={2}>
                        {
                            <Grid.Column>
                                <h1 className="headline-primary">Password Settings</h1>
                            </Grid.Column>
                        }
                        <Grid.Column>
                            <Link to='/user/my-account' className="pull-right floated right" >
                                <svg className="special__icon">
                                    <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
                                </svg>
                            </Link>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {(!!errorMessage) ? <div className="ui negative message"><div className="header">Error</div><p>{this.state.errorMessage}</p></div> : ''}
                {(!!successMessage) ? <div className="ui positive message"><div className="header">Success</div><p>{this.state.successMessage}</p></div> : ''}
                <div id="getemail" className="grid__col--5" style={{ float: "left", marginLeft: "15px" }}>
                    <Form onSubmit={this.onSubmitHandler} >

                        <h4 className="ui header">Minimum Characters: {minimunChars}</h4>
                        <Form.Input
                            min={minChars}
                            max={30}
                            name='minimunChars'
                            onChange={this.onModelPropertyChange}
                            step={1}
                            type='range'
                            value={minimunChars} />
                        <h4 className="ui header">Minimum Uppercase Characters: {uppercaseChars}</h4>
                        <Form.Input
                            min={1}
                            max={10}
                            name='uppercaseChars'
                            onChange={this.onModelPropertyChange}
                            step={1}
                            type='range'
                            value={uppercaseChars} />
                        <h4 className="ui header">Minimum Special Characters or Numbers: {specialChars}</h4>
                        <Form.Input
                            min={1}
                            max={10}
                            name='specialChars'
                            onChange={this.onModelPropertyChange}
                            step={1}
                            type='range'
                            value={specialChars} />

                        <h4 className="ui header">Expiration Password: {expirationMonths} months</h4>
                        <Form.Input
                            min={1}
                            max={12}
                            name='expirationMonths'
                            onChange={this.onModelPropertyChange}
                            step={1}
                            type='range'
                            value={expirationMonths} />
                        <Form.Field>

                            <h4 style={{ display: "inline-block" }} className="ui header">Last five passwords will be saved</h4>
                            <Button style={{ marginLeft: "150px" }} onClick={() => this.onModelPropertyChange({ target: { name: "passwordHistory", value: !passwordHistory } })} type="button"
                                color={passwordHistory ? 'primary' : 'grey'}>{passwordHistory ? 'Enabled' : 'Disabled'}</Button>
                        </Form.Field>
                        {/*<Form.Field>
                            <h1 className="ui header centered">Two Factor Authentication</h1>
                            <Button onClick={() => this.onModelPropertyChange({ target: { name: "twoFA", value: !twoFA } })} type="button"
                                color={twoFA ? 'primary' : 'grey'}>{twoFA ? 'Enabled' : 'Disabled'}</Button>
                        </Form.Field>*/}
                        <Form.Field>
                            <Button className="primary large fluid huge" fluid >Save</Button>
                        </Form.Field>
                    </Form>
                </div>
            </div>
        );
        return view;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) }
    }
}

export default connect(null, mapDispatchToProps)(PasswordSetting);