import React, { Component } from 'react'

export default class IMRow extends Component {

    renderRow() {
        const { row, columns } = this.props;
        if (!row)
            return columns.map((x, i) => <td key={`cell-${i}`}  className={`${x.colWidth} wide`} >&nbsp;</td>);
        else
    return columns.map((x, i) => <td key={`cell-${i}`}  className={`${x.colWidth} wide`} >{x.renderContent?x.renderContent(row):<div style={{overflowX:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}} title={x.render(row)}>{x.render(row)}</div>}</td>);
    }

    render() {
        return (
            <tr>
                {this.renderRow()}
            </tr>

        )
    }
}
