import React from 'react';

import { FilePicker } from 'react-file-picker';

import { Modal, Button, Header, Icon } from 'semantic-ui-react';

class SelectFile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      fileObject: null,
      modalOpen: false,
      selectFileComponent: null
    }
    this.onSelectionFileSuccess=this.onSelectionFileSuccess.bind(this);
    this.onDoneClickHandler=this.onDoneClickHandler.bind(this);
    this.onCloseModalClick=this.onCloseModalClick.bind(this);
  }

  onSelectionFileSuccess = (fileObject) => {
    this.setState({ fileObject, fileName: fileObject.name });
  }

  onDoneClickHandler = () => {
    this.props.onSelectionFileSuccess(this.state.fileObject);
    this.onCloseModalClick();
  }
  onCloseModalClick = () => {
    this.setState({ fileObject: null, fileName: '', modalOpen: false });
  }

  render() {
    return (
      <Modal
            trigger={<Button ref={x => this.selectFileComponent = x} style={{ display: this.props.showComponent ? 'block' : 'none' }} primary onClick={() => this.setState({ modalOpen: true })} size='small'>{this.props.title}</Button>}
        open={this.state.modalOpen}
        onClose={this.onCloseModalClick}
        size='mini'>
        <Header icon='file' content={this.props.title} />
        <Modal.Content>
          {this.props.comment ? <p >{this.props.comment} </p> : null}
          {this.props.commentHtml}
          <FilePicker
            extensions={this.props.types}
            onChange={this.onSelectionFileSuccess}
            onError={errMsg => { this.props.onErrorHandling(errMsg); }}>
            <Button primary>{this.state.fileName !== '' ? this.state.fileName : 'Upload File'}</Button>
          </FilePicker>
        </Modal.Content>
        <Modal.Actions>
          {this.state.fileObject !== null ? (<Button primary inverted onClick={this.onDoneClickHandler}><Icon name='check' />Ok</Button>) : null}
          <Button secondary onClick={this.onCloseModalClick}>Cancel</Button>
        </Modal.Actions>
      </Modal>);
  }
}
export default SelectFile;