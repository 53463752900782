import React, { Component } from 'react'
import { Switch } from 'react-router-dom';

import PropsRoute from '../Components/PropsRoute';

import Edit from './Edit';
import List from './List';


export default class Index extends Component {
    render() {
        return (
            <Switch>
                <PropsRoute exact path="/product/edit/:productId" Component={Edit} goToPage={this.props.goToPage} />
                <PropsRoute exact path="/product/new" Component={Edit} goToPage={this.props.goToPage} />
                <PropsRoute exact path="/product" Component={List} goToPage={this.props.goToPage} />
            </Switch>
        )
    }
}
