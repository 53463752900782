import axios from 'axios';

import history from './history';

const handleStatusCodes = function ({ data, status, ...rest }) {
  switch (status) {
      case 401:
          if (!window.location.href.toLocaleLowerCase().includes('login')) {
              history.push('/login');
          } else {
              return { data, status, ...rest };
          }
      break;
    case 403:
      return { error: 'forbidden' };
    case 404:
      history.push('/404');
      return;
    case 500:
      console.error(`status ${status}: ${JSON.stringify(data)}`);
      history.push('/500');
      return;
    default:
      console.error(`status ${status}: ${JSON.stringify(data)}`);
      return { data, status, ...rest };
  }
};
const baseUrl = ".";

const BEConfig = {
  baseURL: `${baseUrl}/api`,
};

const processResponse = function (response) {
  return response.data
};


const addConfiguration = function (params) {
  return ({ ...BEConfig, ...params });
}

const handleError = (error) => {
  if (error.response) {
    throw handleStatusCodes(error.response);
  }
  if (error.request) {
    console.error(error.request);
    throw new Error('The request was made but no response was received');
  }
  console.error('Error', error.message);
  console.error(error.config);
  throw new Error(
    'Something happened in setting up the request that triggered an Error'
  );
};

const request = function (config) {
  return axios
    .request(addConfiguration(config))
    .then(processResponse)
    .catch(handleError);
}

export { request };
