import React, { Component } from 'react'
import { Switch } from 'react-router-dom';

import PropsRoute from '../Components/PropsRoute';
import ResetPassword from '../../components/ResetPassword';

import List from './List';
import MyAccount from './MyAccount';
import PasswordSetting from './PasswordSetting';
import EditUser from './EditUser';


export default class Index extends Component {
    render() {
        return (
            <Switch>
                <PropsRoute exact path="/user/my-account" component={MyAccount} />
                <PropsRoute exact path="/user/list" component={List} />
                <PropsRoute exact path="/user/password-setting" component={PasswordSetting}/>
                <PropsRoute exact path="/ResetPassword" component={ResetPassword} />
                <PropsRoute exact path="/user/Edit/:userId" component={EditUser} administrator={true} />
                <PropsRoute exact path="/user/new" component={EditUser} administrator={true} />
            </Switch>
        )
    }
}
