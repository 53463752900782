import React from 'react';

import ReactDOM from 'react-dom';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import Main from './components/Main';
import './scss/app.scss';
import { Provider } from 'react-redux';
import store from './redux/reducers/index'

const rootElement = document.getElementById('app');

ReactDOM.render(
    <Provider store={store}>
        <Main />
    </Provider>, rootElement);