import React from 'react';

import matchSorter from 'match-sorter';

import { withRouter } from 'react-router-dom';

import axios from 'axios';

import 'react-widgets/dist/css/react-widgets.css';

import { Dropdown, Grid } from 'semantic-ui-react';

import LicenseGrid from '../application/License/LicenseGrid';

import { convertDate } from './utility/Helper';

class License extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //licenseUserlist: null,
      manage: true,
      users: [],
      selectedUser: '',
      filtered: [{
        'id': 'Status',
        'value': 'true'
      }]
    };
    this.handleLicenseUsageFilter = this.handleLicenseUsageFilter.bind(this);
    this.handleLicenseEdit = this.handleLicenseEdit.bind(this);
    this.onLicenseFilterChanged = this.onLicenseFilterChanged.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleLicenseUsageFilter = function (e) {
    e.persist()
    const licenseId = e.target.id;
    this.setState({ manage: false });
    this.props.history.push(`./license-usage/${licenseId}`);
  }

  handleLicenseEdit = function (e) {
    e.persist()
    const licenseId = e.target.id;
    this.setState({ manage: false });
    this.props.history.push(`./license/edit/${licenseId}`);
  }

  componentDidMount = function () {

    const requesturl = 'api/Licenses/Users';
    const self = this;
    return axios.get(requesturl)
      .then(function (response) {

        if (response && response.data) {
          self.setState({ users: response.data })
        }
      })
      .catch(function (e) {
        console.error(e.status, e.toString());
      });
  }

  onLicenseFilterChanged = function (queryProduct, queryStatus, queryEmail, queryType, orderBy, filter) {
    this.props.onLicenseFilterChanged(queryProduct, queryStatus, queryEmail, queryType, orderBy, filter);
  }

  handleSearchChange = function (e, option) {
    this.setState({ selectedUser: option.value, filtered: [{ id: 'encryptedId', value: option.value.split('-')[0] }] });
  }

  render() {
    let options = this.state.users.map((item, index) => ({ key: item.cryptId, value: item.licenseCryptId + '-' + index, text: item.accountName + ' - ' + item.firstName + ' ' + item.lastName }))
    const { data } = this.props;
    return (
      <div>
        <Grid style={{ width: "100%", maxWidth: "none" }}>
          {/*{data.length?
        <LicenseGrid data={data} isAdmin={this.props.isAdmin} handleLicenseEdit={this.handleLicenseEdit} 
        handleLicenseUsageFilter={this.handleLicenseUsageFilter} ></LicenseGrid>
        :null
      }*/}
          <LicenseGrid data={data} isAdmin={this.props.isAdmin} handleLicenseEdit={this.handleLicenseEdit}
            handleLicenseUsageFilter={this.handleLicenseUsageFilter} onLicenseFilterChanged={this.onLicenseFilterChanged} ></LicenseGrid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(License);
