import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios';

import { onUserChange } from '../../components/Main';

export default class IMNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                detailType: ''
            }
        }
        this.handleLogout=this.handleLogout.bind(this);
    }

    handleClick = () => {
        this.props.onNavigationEvent();

    }
    handleLogout = function () {
        this.props.onNavigationEvent();
        const self = this;
        return Axios.post('/api/Logout')
            .then(function () {
            onUserChange();
        })
            .catch(function (e) {
                console.error(e);
            });
    }
    render() {
        const navClass = this.props.isOpen ? 'nav is__visible' : 'nav';
        return (
            <nav className={navClass}>
                <ul className="nav__container">
                    <li className={this.state.data.detailType === 'License' ? 'nav__item active' : 'nav__item'}><Link to='/' onClick={this.handleClick}>LICENSES</Link></li>
                    <li className="nav__item"><Link to='/user/my-account' onClick={this.handleClick}>MY PROFILE</Link></li>
                    {this.props.isAdmin ? (<li className="nav__item"><Link to='/user/list' onClick={this.handleClick}>USERS</Link></li>) : null}
                    {/*this.props.isAdmin ? (<li className="nav__item"><Link to='/single-sign-on' onClick={this.handleClick}>SINGLE SIGN ON</Link></li>) : null*/}
                    {this.props.isAdmin ? (<li className="nav__item"><Link to='/product' onClick={this.handleClick}>PRODUCTS</Link></li>) : null}
                    <li className="nav__item"><a className="nav__link" href="http://support.informationmapping.com/" target="_blank">SUPPORT &amp; FAQ</a></li>
                    {/*<li className="nav__item"><button className="nav__link--button button ui fluid grey" onClick={this.handleLogout} >LOG OUT</button></li>*/}
                    <li className="nav__item"><Link to="#" onClick={this.handleLogout} >LOG OUT</Link></li>
                </ul>
            </nav>
        )
    }
}
