import React, { Fragment } from 'react'
import { Popup } from 'semantic-ui-react';


export function IMErrorComponent({ children, error,position }) {

    return (
        <Fragment>
            <Popup
            color="red"
            className="red error"
            
                trigger={children}
                content={error}
                position={position}
                open={!!error} />
        </Fragment>
    );
}

