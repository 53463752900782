import React from 'react';
import { withRouter } from 'react-router-dom';


class ViewDetailWrapper extends React.Component {
  constructor(props) {
    const errorUrlMessage = new URLSearchParams(props.location.search).get("error");
    super(props);
    this.state = {
      errorMessage: errorUrlMessage === null ? "" : errorUrlMessage,
      successMessage: '',
    };
  }
  showSuccessMessage = (msg) => {
    this.setState({ successMessage: msg, errorMessage: "" });
  }
  showErrorMessage = (msg) => {
    this.setState({ errorMessage: msg, successMessage: "" });
  }

  render() {
    const self = this;
    return (
      <div>
        <div className="grid">
          <header className="grid__col--12 panel--padded--centered" role="banner">
            <a className="site-logo left" href="https://www.informationmapping.com/">
              <b className="srt">Information Mapping Logo</b>
            </a>
            <h1 className="headline-primary--grouped right"><a className="site-logo-trial" href="/">FS Pro Console</a></h1>
          </header>
        </div>
        <div className="grid">
          <div className="centered grid__col--8">
            <h2 className="headline-secondary--centered">{this.props.headerMessage}</h2>
          </div>
        </div>
        <div className="grid padded">
          <div id="getemail" className="centered grid__col--5 panel__inverse--padded">
            {(this.state.errorMessage !== "") ?
              <div className="ui negative message">
                <div className="header">Verification Login</div>
                <p>{this.state.errorMessage}</p></div> : ''}
            {(this.state.successMessage !== "") ?
              <div className="ui positive message">
                <div className="header">Verification Login</div>
                <p>{this.state.successMessage}</p></div> : ''}
            {React.cloneElement(
              this.props.children,
              {
                showErrorMessage : this.showErrorMessage,
                showSuccessMessage: this.showSuccessMessage,
                history: self.props.history
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter( ViewDetailWrapper);
