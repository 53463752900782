import { stat } from 'fs';
import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Header, Icon, Modal, Segment } from 'semantic-ui-react';

class LoadingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: false
    };
  }
  componentDidMount() {
  }



  render() {
    const { loadingState } = this.state
    const loadingLabelStyle = { top: '-60px', right: '409px' };
    return <Dimmer active={this.props.loading.loading} page={true}>
      <div className="loading">
        <div className="bar">
          <div className="circle"></div><br />
          <p style={loadingLabelStyle} >Loading...</p>
        </div>
      </div>
    </Dimmer>;
  }
}

const mapStateToProps = function (state) {
  return {
    loading: state.application.loading
  }
}

export default connect(mapStateToProps)(LoadingPage)