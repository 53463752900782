import React from 'react';
import { Button } from 'semantic-ui-react';

import { factoryGeneralState } from './Main';

class LicensesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.saveInGeneral = factoryGeneralState('License');
    this.state = {
      emailPart: this.saveInGeneral.data.emailPart || ""
    };

    this.handleSearch();
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleDataChange = (e) => {
    switch (e.target.name) {
      case 'txtemail':
        this.setState({ emailPart: e.target.value });
        break;
      default:
        break;
    }
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleSearch = () => {
    const emailPart = this.state.emailPart;
    const format = /[!#$%^&*()+=\[\]{};':"\\|,<>\/?]+/;
    if (format.test(emailPart)) {
      if (!this.validateEmail(emailPart)) {
        alert("Please specify valid characters for names or valid email formats");
        return;
      }

    }
    this.saveInGeneral.data = { emailPart: emailPart };
    this.props.handleFilter(emailPart);
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      e.preventDefault();
      this.handleSearch();
    }
  }

  render() {
    return (
      <div className="ui grid">
        <div className="twelve wide column centered" style={{ marginLeft: "0px" }}>
          <h2 className="headline-search">Help me look for:</h2>
        </div>

        <div className="twelve wide column centered" style={{ marginLeft: "0px" }}>
          <div className="ui icon action input fluid">
            <input type="text" placeholder="john@company.com" onKeyPress={this.handleKeyPress} onChange={this.handleDataChange} name="txtemail" value={this.state.emailPart} />
                    {(!this.props.searching) ? <Button className="ui primary button" style={{ 'border-radius': '0px', backgroundColor: '#FF8019' }} onClick={this.handleSearch} >SEARCH</Button> :
              <button className="ui submit button"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></button>}
          </div>


        </div>
      </div>

    );
  }
}

export default LicensesFilter;
