import React from 'react';

import axios from 'axios';

class VerificationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: ""
    };
    this.onLogOutClickHandler=this.onLogOutClickHandler.bind(this);
  }
  componentDidMount() {
  }

  onSendCodeClickHandler = () => {
    var self = this;
    this.props.showErrorMessage("");
    const sender = axios.get(`/api/Authenticate/VerifyCode?code=${this.state.verificationCode}`);
    if (sender)
      sender
        .then(function () {
          self.props.onUserChange();
        })
        .catch(function (error) {
          self.props.showErrorMessage(error.response.data)
        });
  }

  onLogOutClickHandler = function () {
    const self = this;
    
    return axios.post('/api/Logout').then(function (response) {

      if (response) {
        self.props.onUserChange();
        window.location.href = '/';
      }
    }).catch(function (e) {
      console.error(e);
    });
  }

  handleVerificationCodeSubmit = () => {
    this.props.showErrorMessage("The code sent is incorrect");
    this.setState({ sending: true, verificationCode: "" })
  }

  handleVerificationCodeChanged = (e) => {
    e.preventDefault();
    this.setState({ verificationCode: e.target.value })
  }

  onSendSmsVerificationClickHandler = (e) => {
    e.preventDefault();
    var self = this;
    const sender = axios.get('/api/Authenticate/SendSms');
    if (sender)
      sender
        .then(function () {
          self.props.showSuccessMessage("A code was already sent to your phone.")
        })
        .catch(function (error) {
          self.props.showErrorMessage(error.response.data)
        });
  }
  onSendEmailVerificationClickHandler = (e) => {
    e.preventDefault();
    var self = this;
    const sender = axios.get('/api/Authenticate/SendEmail');
    if (sender)
      sender
        .then(function () {
          self.props.showSuccessMessage("A code was sent to your email.")
        })
        .catch(function (error) {
          self.props.showErrorMessage(error.response.data)
        });
  }
  render() {
    let verificationView = (
      <form className="form" action="/Account/ExternalLogin" method="POST">
        <div className="form__group">
          <p className="form__text">We will send you a verfication Message.</p>

          {(this.state.sending) ? <button className="form__btn" ><div className="lds-ellipsis"></div></button>
            : <div className="ui large buttons blue centered fluid">
              <button className="ui button" onClick={this.onSendSmsVerificationClickHandler} disabled={!this.props.sms}>SMS</button>
              <div className="or"></div>
              <button className="ui button" onClick={this.onSendEmailVerificationClickHandler}>Email</button>
            </div>}
          <div className="ui horizontal divider"></div>

          <input
            className="form__input"
            type="text"
            placeholder="XXXX"
            value={this.state.verificationCode}
            onChange={this.handleVerificationCodeChanged}

          />
          <input className="blue ui button fluid" type="button" value="Verify Code" onClick={this.onSendCodeClickHandler} />
          <div className="ui horizontal divider">Or use</div>
          <div className="ui large buttons centered fluid">
            <button className="ui blue button" name="Provider" type="submit" value="Microsoft"><i className="microsoft icon"></i>Microsoft</button>
            <div className="or"></div>
            <button className="ui google plus button" name="Provider" type="submit" value="Google"><i className="google icon"></i>Google</button>
          </div>
          <div className="ui horizontal divider">Or</div>
          <input className="ui blue button fluid" type="button" onClick={this.onLogOutClickHandler} value="LOG OUT" />
        </div>
      </form>
    );
    return verificationView;
  }
}

export default VerificationCode;
