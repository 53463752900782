import React from 'react';

import axios from 'axios';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Modal, Button, Grid } from 'semantic-ui-react';

import Index from '../application/Index';

import Application from './Application';
import ForgotPassword from './ForgotPassword';
import LoadingPage from './LoadingPage';
import Login from './Login';
import ResetPassword from './ResetPassword';
import VerificationCode from './VerificationCode';
import ViewDetailWrapper from './ViewDetailWrapper';
import { connect } from 'react-redux';
import { applicationActionCreator } from '../redux/actions/applicationActions'
import IMToastr from '../application/Components/IM/IMToastr';
import Logout from './Logout';

export let factoryGeneralState, onUserChange;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.userName = "";
        this.state = {
            authenticated: false,
            tried: false,
            needVerification: false,
            data: {},
            showCountryCodeFillMsg: false
        };
        this.loadCurrentUser = this.loadCurrentUser.bind(this);
        this.closeCountryCodeFillMsg = this.closeCountryCodeFillMsg.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        let self = this;

        onUserChange = self.loadCurrentUser;
        factoryGeneralState = (viewName) => {
            return {
                get data() {
                    let value = self.getGeneralState(`${viewName}View`);
                    if (value === undefined)
                        return {};
                    else
                        return value;
                },
                set data(value) {
                    self.saveToGeneral(`${viewName}View`, value)
                }
            }
        }
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.logout = this.logout.bind(this);
        this.loadCurrentUser = this.loadCurrentUser.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);

        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
    }

    clearTimeout() {
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }

    setTimeout() {
        this.logoutTimeout = setTimeout(this.logout, 1800 * 1000); //30min
    }

    resetTimeout() {
        this.clearTimeout();
        this.setTimeout();
    }

    logout = function () {
        const self = this;
        return axios.post('/api/Logout')
            .then(function () {
                self.setState({ authenticated: false, tried: true, data: { name: "" } });
                self.destroy(); // Cleanup
            })
            .catch(function (e) {

                self.setState({ authenticated: false, tried: true, data: { name: "" } });
                console.error(e);
            });
    }

    destroy() {
        this.clearTimeout();

        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    saveToGeneral = (viewName, data = null) => {
        let newState = null;
        if (data !== null) {
            let viewState = this.state[viewName];
            newState = { ...viewState, ...data };
        }
        this.setState({ [viewName]: newState });
    }

    getGeneralState = function (viewName) {
        return this.state[viewName];
    }

    loadCurrentUser = function () {
        const self = this;
        axios.get('/api/CurrentUser', { params: { version: Math.random() } })
            .then(function (user) {

                if (user) {
                    let userData;
                    if (user.data != null) {
                        if (user.data.needVerification) {
                            userData = { authenticated: true, needVerification: true, data: { name: "" }, sms: user.data.sms };
                        } else {
                            userData = { authenticated: true, tried: true, needVerification: false, data: user.data };
                        }
                        // Country field is empty. Therefore, need to ask of user to fill it.
                        if (self.checkCountryCode && !userData.data.countryCode) {
                            self.userName = userData.data.name;
                            self.setState({ showCountryCodeFillMsg: !self.state.showCountryCodeFillMsg });
                        }
                    } else {
                        userData = { authenticated: false, tried: true, data: { name: "" } };
                    }
                    self.props.onSetCurrentUser(userData);
                    self.setState(userData);
                }
            })
            .catch(function (e) {
                self.setState({ authenticated: false, tried: true, data: { name: "" } });
                console.error(e);

            })
    }

    componentDidMount = () => {
        this.loadCurrentUser();
    }

    handleClick = () => {
        this.props.onNavigationEvent();
    }

    closeCountryCodeFillMsg = () => {
        const self = this;
        self.setState({ showCountryCodeFillMsg: false });
    }

    render() {
        let mainApp = <div />;

        const pathname = window.location.pathname;

        if (pathname.startsWith('/account/login/referer')) {
            mainApp =
                <Router>
                    <Switch>
                        <PropsRoute exact path="/Login/:provider" component={Login} />
                        <PropsRoute exact path="/account/login/referer/:tokenExternalId" component={Login} />
                    </Switch>
                </Router>;
        } else if (this.state.needVerification && this.state.authenticated) {
            mainApp = <Router>
                <Switch>
                    <PropsRoute exact path="/Login/:provider" component={Login} />
                    <PropsRoute exact path="/ForgotPassword" component={ForgotPassword} onUserChange={this.loadCurrentUser} />
                    <PropsRoute exact path="/Logout/:Provider" component={Logout} />
                    <PropsRoute exact path="/ResetPassword" component={ResetPassword} userName={this.state.data.name} isAdmin={this.state.data.administrator} onUserChange={this.loadCurrentUser} />
                    <Route render={() =>
                        <ViewDetailWrapper>
                            <VerificationCode onUserChange={this.loadCurrentUser} sms={this.state.sms} />
                        </ViewDetailWrapper>} />
                </Switch>
            </Router>;
        }
        else if (this.state.authenticated) {
            mainApp =
                <Router>
                    <Switch>
                        <PropsRoute exact path="/Logout/:Provider" component={Logout} />
                        <PropsRoute exact path="/Login/:provider" component={Login} />
                        <PropsRoute exact path="/" component={Application} userName={this.state.data.name} isAdmin={this.state.data.administrator} onUserChange={this.loadCurrentUser} />
                        <PropsRoute path="/license-usage" onUserChange={this.loadCurrentUser} component={Index} userInformation={{ ...this.state.data, needVerification: false }} />
                        <PropsRoute exact path="/ResetPassword" component={ResetPassword} userName={this.state.data.name} isAdmin={this.state.data.administrator} onUserChange={this.loadCurrentUser} />
                        <PropsRoute path="/single-sign-on" onUserChange={this.loadCurrentUser} component={Index} userInformation={{ ...this.state.data, needVerification: false }} />
                        <PropsRoute path="/license" onUserChange={this.loadCurrentUser} component={Index} userInformation={{ ...this.state.data, needVerification: false }} />
                        <PropsRoute path="/user" onUserChange={this.loadCurrentUser} component={Index} userInformation={{ ...this.state.data, needVerification: false }} />
                        <PropsRoute path="/product" onUserChange={this.loadCurrentUser} component={Index} userInformation={{ ...this.state.data, needVerification: false }} />
                        <PropsRoute path="/:detailName" onUserChange={this.loadCurrentUser} component={Application} userName={this.state.data.name} isAdmin={this.state.data.administrator} isManager={this.state.data.isManager} />
                        <Route component={NoMatch} />
                    </Switch>
                </Router>;
        }
        else if (this.state.tried) {
            mainApp =
                <Router>
                    <Switch>
                        <PropsRoute exact path="/Logout/:provider" component={Logout} />
                        <PropsRoute exact path="/Login/:provider" component={Login} />
                        <PropsRoute exact path="/ForgotPassword" component={ForgotPassword} onUserChange={this.loadCurrentUser} />
                        <PropsRoute exact path="/ResetPassword" component={ResetPassword} userName={this.state.data.name} isAdmin={this.state.data.administrator} onUserChange={this.loadCurrentUser} />
                        <Route render={() => <Login onUserChange={this.loadCurrentUser} onUserLogined={() => this.checkCountryCode = true} />} />
                    </Switch>
                </Router>;
        } else {
            mainApp = <div />;
        }
        return <div>
            <LoadingPage />
            <IMToastr />
            {mainApp}
            <Modal size="tiny" open={this.state.showCountryCodeFillMsg} onClose={() => this.setState({ showCountryCodeFillMsg: false })}>
                <Modal.Header>We want to improve your experience</Modal.Header>
                <Modal.Content style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <Modal.Description>
                        <Grid columns={16} style={{ paddingTop: '0px' }}>
                            <Grid.Row textAlign='left'>
                                <p>Hi {this.userName},</p>
                                <span>For a tailored experience, we'd like to know which country you're from. It'll only take a moment! Please update your country in the settings.</span>
                            </Grid.Row>
                            <Grid.Row textAlign='left' style={{ paddingTop: '0px' }}>
                                <Grid.Column width='16' style={{ paddingLeft: '0px' }}>
                                    <div className="floated left align">
                                        <Router>
                                            <Switch>
                                                <Button primary size='medium'><Link style={{ color: '#FFFFFF' }} to='/user/my-account' onClick={this.handleClick}>Update Country</Link></Button>
                                            </Switch>
                                        </Router>
                                        <Router>
                                            <Switch>
                                                <Button secondary size='medium' content='Maybe Later' onClick={this.closeCountryCodeFillMsg}></Button>
                                            </Switch>
                                        </Router>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row textAlign='left'>
                                <span>Thank you for being a part of our community!</span>
                                <span style={{ fontSize: '10px' }}>Your privacy is important to us. Click to read our <a style={{ color: '#009EDF' }} href="https://fspro-cloud.informationmapping.com/document/5221/a5qf1ruubf0fqdj20ib0prjb89/#home" target='_blank'>Privacy Policy</a></span>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </div>;
    }
}
const NoMatch = ({ location }) => (
    <div>
        <h3>No match for <code>{location.pathname}</code></h3>
    </div>
);

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return (
        React.createElement(component, finalProps)
    );
};

const PropsRoute = ({ component, ...rest }) => {
    return (
        <Route {...rest} render={(routeProps) => {
            return renderMergedProps(component, routeProps, rest);
        }} />
    );
};


function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
        onSetCurrentUser: function (payload) {
            dispatch(applicationActionCreator.setCurrentUser(payload))
        }
    }
}

export default connect(null, mapDispatchToProps)(Main);
