
import { Route } from 'react-router-dom'
import React from 'react';

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
};

const PropsRoute = ({ Component, ...rest }) => {
  return (
    <Route {...rest} render={(routeProps) => {
      return renderMergedProps(Component, routeProps, rest);
    }} />
  );
};

export default PropsRoute;