import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react';

export let confirmationModal = function (message) {
    return new Promise((res, rej) => { res(); });
}
export default class ModalComponent extends Component {
    okConfirmationButton = () => { }
    cancelConfirmationButton = () => { }
    constructor(props) {
        super(props);
        this.state = {
            confirmation: {}
        };

        confirmationModal = (title, message) => {
            this.setState({ confirmation: { title, message }, openConfirmationModal: true });
            const self = this;
            const promise = new Promise((res, rej) => {
                self.okConfirmationButton = () => {
                    self.setState({ openConfirmationModal: false });
                    res();
                }
                self.cancelConfirmationButton = () => {
                    self.setState({ openConfirmationModal: false });
                    rej();
                }
            })

            return promise;
        }
    }

    render() {
        const { openConfirmationModal, confirmation: { message, title }, loadingState } = this.state;
        return (
            <div>
                <Modal open={openConfirmationModal}>
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Content  >
                        {message}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={this.okConfirmationButton}>YES</Button>
                        <Button color="blue" onClick={this.cancelConfirmationButton} content='CANCEL' />
                    </Modal.Actions>
                </Modal>
                <Modal
                    open={loadingState}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    basic>
                    <Modal.Header>Processing...</Modal.Header>
                    <Modal.Content>
                        <div className="loading" hidden={!this.props.loadingState}>
                            <div className="bar">
                                <div className="circle"></div>
                                <p>Loading</p>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}
