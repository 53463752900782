import React, { } from 'react'
import Axios from 'axios';

import { Input } from 'semantic-ui-react';

import { goToPageNavigation } from '../Index';
import IMComponent from '../Components/IMComponent';
import { confirmationModal } from '../Components/ModalComponent';
import IMTable from '../Components/IM/IMTable';
import applicationActionCreator, { actions } from '../../redux/actions/applicationActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class List extends IMComponent {

    constructor(props) {
        super(props)
        this.loadData();
    }

    loadData() {
        const self = this;
        Axios.get("/api/singlesignon")
            .then(function (response) {
                self.setState({ data: response.data });
            })
    }


    deleteSSO = function (row, siteName) {
        const SSOId = row.target.id;
        const self = this;
        this.props.showLoading('DELETE_SSO');
        return confirmationModal('Delete SSO?', `Are you sure to delete '${siteName}' SSO?`)
            .then(function () {
                self.setState({ data: [] });
            })
            .then(function () {
                return Axios.delete(`/api/SingleSignOn/${SSOId}`);
            })
            .then(function () {
                self.loadData();
                self.showToastSuccess({ title: "Single Site On", message: "Deleted successfully." })
            })
            .catch(function (e) {
                if (e)
                    console.error(self.props.match.url, e.status, e.toString());
            })
            .then(function () {
                self.props.hideLoading('DELETE_SSO')
            })
    }


    showSSOSiteEdit(row) {
        const siteId = row.target.id;
        goToPageNavigation(`/single-sign-on/edit/${siteId}`);
    }

    showProductNew = () => {
        goToPageNavigation("/single-sign-on/new");
    }

    render() {
        const columns = [
            {
                header: "Name",
                identificator: "name",
                render: (row) => row.name,
                colWidth: "two",
                sortable: true,
                filterRender: (filterCallback) =>
                    <Input className="fluid" onChange={({ target: { value } }) => filterCallback(value)}></Input>
            },
            {
                colWidth: "five",
                header: "URL",
                sortable: true,
                render: (row) => row.url,
                identificator: "url",
                filterRender: (filterCallback) =>
                    <Input className="fluid" onChange={({ target: { value } }) => filterCallback(value)}></Input>
            },
            {
                colWidth: "seven",
                header: "Public Key",
                identificator: "publicKey",
                sortable: true,
                render: (row) => row.publicKey
            },
            {
                colWidth: "two",
                header: "",
                renderContent: (row) => <div>
                    <button type="button" className='ui button primary btn fluid'
                        id={row.id}
                        onClick={this.showSSOSiteEdit}
                    > <i className="fas fa-pencil-alt"></i> Edit</button>
                    <button type="button" className='ui button red btn fluid'
                        id={row.id}
                        onClick={(r) => this.deleteSSO(r, row.name)}
                    > <i className="fas fa-trash-alt"></i>  Delete</button>

                </div>
            }
        ]
        const { data } = this.state;

        return (<div>
            <h1 className="headline-primary">Single Sign On Sites</h1>
            <button onClick={this.showProductNew}
                className='ui button primary btn pull-right'>
                <i className='fas fa-plus'></i> Add Site</button>
            <br />
            {!!data ?
                <IMTable
                    hasFilter={true}
                    data={data}
                    columns={columns}></IMTable> : null}
        </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
        successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) },
        errorMessage: function (message) { return dispatch(applicationActionCreator.notification.error(message)) },
    }
}

export default connect(null, mapDispatchToProps)(List);