import React, { Component } from 'react'
import IMRow from './IMRow'
import IMRowHeader from './IMRowHeader';
import _ from 'underscore';
import { Dropdown, Select, Pagination } from 'semantic-ui-react';

export default class IMTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalItems: props.data,
            filters: {},
            paginationSize: 5,
            activePage: 1
        }
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.filterRows = this.filterRows.bind(this);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.onPaginationSizeChanged = this.onPaginationSizeChanged.bind(this);
        this.sortColumn = this.sortColumn.bind(this);
    }

    renderTableBody(data) {
        if (!data)
            return null
        return data.map((x, i) => (<IMRow row={x} columns={this.props.columns} key={"row" + i}></IMRow>));
    }

    filterRows() {
        let { originalItems, filters } = this.state;
        for (let index = 0; index < this.props.columns.length; index++) {
            const column = this.props.columns[index];
            const filterColumn = filters[column.identificator];
            if (filterColumn) {
                originalItems = _.filter(originalItems, (row) => column.render(row).includes(filterColumn));
            }
        }
        const { paginationSize, activePage } = this.state;
        const originalItemsCount = originalItems.length;
        originalItems = [...originalItems.slice((activePage - 1) * paginationSize, activePage * paginationSize)];
        const itemsLeft = paginationSize - originalItems.length;

        for (let index = 0; index < itemsLeft; index++) {
            originalItems.push(null);
        }
        return { items: originalItems, count: originalItemsCount };
    }

    onFilterChanged(id, value) {
        const { filters } = this.state;
        filters[id] = value;

        this.setState({
            filters,
            activePage: 1
        });
        this.forceUpdate();
    }

    handlePaginationChange(e, { activePage }) {
        this.setState({ activePage });
    }

    onPaginationSizeChanged(e, { value }) {
        this.setState({ paginationSize: value });
    }

    sortColumn(columnId) {
        let { originalItems, columnSort, direction } = this.state;
        const columnSorting = this.props.columns.filter(x => x.identificator === columnId)[0];
        originalItems = _.sortBy(originalItems, x => columnSorting.render(x).toLowerCase());
        if (direction === "desc") {
            originalItems = _.chain(originalItems).reverse().value();
        }
        this.setState({
            originalItems,
            columnSort: columnId,
            direction: (columnSort === columnId && direction === "asc" ? "desc" : "asc")
        });
    }

    render() {
        const rows = this.filterRows();
        const hasFilters = this.props.columns.filter(x => !!x.filterRender).length > 0;
        const { paginationSize, activePage, columnSort, direction } = this.state;
        const maxPagination = Math.ceil(rows.count / paginationSize);
        const paginationOptions = [
            { key: '5', value: 5, text: '5 rows' },
            { key: '10', value: 10, text: '10 rows' },
            { key: '15', value: 15, text: '15 rows' },
            { key: '20', value: 20, text: '20 rows' },
            { key: '25', value: 25, text: '25 rows' },
        ]
        return (

            <div className="ui basic segment" style={{ tableLayout: "fixed" }}>
                <table className="ui selectable celled structured table" style={{ tableLayout: "fixed" }}>
                    <IMRowHeader sortColumn={this.sortColumn} 
                    columns={this.props.columns} hasFilter={hasFilters} 
                    columnSort={columnSort}
                    directionSort={direction}
                    onFilterChanged={this.onFilterChanged}></IMRowHeader>
                    <tbody>
                        {this.renderTableBody(rows.items)}
                    </tbody>
                    <tfoot>
                        <tr><th colSpan={this.props.columns.length}>
                            {rows.count !== 0 ? (
                                <div>
                                    <div className="ui left aligned segment">
                                        <Pagination
                                            activePage={activePage}
                                            onPageChange={this.handlePaginationChange}
                                            totalPages={maxPagination} />

                                    </div>
                                    <div className="ui right aligned segment">
                                        <span>Pagination: </span>
                                        <Dropdown options={paginationOptions} value={paginationSize} onChange={this.onPaginationSizeChanged} />
                                    </div></div>)
                                : null}
                        </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        )
    }
}
