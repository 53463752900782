import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom';
import PropsRoute from '../Components/PropsRoute';

import Edit from './Edit';
import LicenseGridForMerging from './LicenseGridForMerging';

export default class Index extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/license/new" component={Edit} onNavigationEvent={this.onNavigationEvent} />                
                <PropsRoute exact path="/license/edit/:encryptedLicenseId" Component={Edit} goToPage={this.props.goToPage} />
                <Route exact path="/license/merging" render={(props) => <LicenseGridForMerging {...props} />} />
            </Switch>
        )
    }
}
