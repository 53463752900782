import React, { } from 'react'
import Axios from 'axios';

import { Link } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { goToPageNavigation } from '../Index';
import CenteredMidLayout from '../Components/CenteredMidLayout';
import IMComponent from '../Components/IMComponent';

export default class Edit extends IMComponent {

    constructor(props) {
        super(props)
        if (this.props.isEdit) {
            this.loadSSOSite();
        } else {
            this.loadNewKeys();
        }
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
    }

    loadNewKeys() {
        const self = this;
        return Axios.get("api/SingleSignOn/new")
            .then(function (response) {
                self.setState({ model: response.data });
            });
    }

    loadSSOSite() {
        const self = this;
        return Axios.get(`api/SingleSignOn/${this.props.match.params.siteId}`)
            .then(function (response) {
                self.setState({ model: response.data });
            })
    }

    onSaveChangesClick() {
        const self = this;
        if (this.props.isEdit) {
            Axios.put("api/SingleSignOn", this.state.model)
                .then(function () {
                    self.showToastSuccess({ title: "Single Sign On", message: "Updated" });
                })

        } else {
            Axios.post("api/SingleSignOn", this.state.model)
                .then(function () {
                    self.showToastSuccess({ title: "Single Sign On", message: "Created" });
                })
        }

        goToPageNavigation("/single-sign-on");
    }

    render() {

        const saveChangeslabel = this.props.isEdit ? " Update" : " Create";
        const { validModel } = this.state;
        const { name, url, publicKey, privateKey } = this.state.model;
        let formClassName = this.props.isEdit ? 'col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3 no__padding' : 'col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3';

        return (
            <div className={formClassName}>

                <div class="ui grid">
                    <div class="four column row">
                        <div class="left floated column"><h1 className="headline-primary">Single Sign On</h1></div>
                        <div class="right floated column">
                            <Link to='/single-sign-on' className="pull-right" >
                                <svg className="special__icon">
                                    <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
                                </svg>
                            </Link></div>
                    </div>
                </div>
                <CenteredMidLayout>
                    <Form onSubmit={this.onClickSubmitHandler}>
                        <Form.Input value={name} name="name" onChange={this.onModelPropertyChange} label="Name"></Form.Input>
                        <Form.Input value={url} name="url" onChange={this.onModelPropertyChange} label="URL"></Form.Input>
                        <Form.Input value={publicKey} name="publicKey" onChange={this.onModelPropertyChange} label="Public Key"></Form.Input>
                        {this.props.isEdit ? null :
                            <Form.Input value={privateKey} name="privateKey" onChange={this.onModelPropertyChange} label="Private Key"></Form.Input>
                        }
                        <Form.Button content={saveChangeslabel} fluid primary size="huge" disabled={!validModel} onClick={this.onSaveChangesClick} />
                    </Form>
                </CenteredMidLayout>
            </div>
        )
    }
}
