import React from 'react';
import queryString from 'query-string';
import { Popup } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { getPasswordValidation } from './utility/Helper';
import { connect } from 'react-redux';
import applicationActionCreator from '../redux/actions/applicationActions';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: "",
      newPassword: "",
      errorMessage: "",
      passwordHelper: false,
      validations: getPasswordValidation(""),
      token: queryString.parse(this.props.location.search).token
    };

    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleResetPasswordSubmit = this.handleResetPasswordSubmit.bind(this);
    this.onPasswordChangeSuccessfully = this.onPasswordChangeSuccessfully.bind(this);
    this.passwordSetting();
  }

  passwordSetting() {
    const self = this;
    self.props.showLoading("LoadPasswordSettings");
    axios.get(`/api/PasswordSettings?t=${encodeURIComponent(this.state.token)}`)
      .then((response) => { self.setState({ passwordSetting: response.data }) })
      .catch((e) => { self.setState({ errorMessage: e.response.data }); })
      .then(() => {
        self.onPasswordChange("");
        self.props.hideLoading("LoadPasswordSettings");
        self.setState({ passwordHelper: true });
      });
  }

  onPasswordChange = (newVal) => {

    var validations = getPasswordValidation(newVal, this.state.passwordSetting);

    this.setState({
      newPassword: newVal,
      validations
    });
  }

  handleNewPasswordChange(e) {
    var newVal = e.target.value;
    this.onPasswordChange(newVal);
  }
  handleConfirmPasswordChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }
  onPasswordChangeSuccessfully() {
    window.location = "/";
  }

  handleResetPasswordSubmit(e) {
    this.setState({ isLoading: true });
    e.preventDefault();
    var self = this;
    try {
      if (this.state.validations.filter(x => !x.status).length > 0) {
        this.setState({ errorMessage: "Password did not match Password Policy!" });
        return;
      }
      if (this.state.newPassword !== this.state.confirmPassword) {
        this.setState({ errorMessage: "Password did not match! Please try again!" });
        return;
      }
      axios.put(`/api/ResetPassword?t=${encodeURIComponent(this.state.token)}&p=${encodeURIComponent(this.state.newPassword)}`)
        .then(function () {
          self.onPasswordChangeSuccessfully();

          self.setState({ isLoading: false });
        })
        .catch(function (error) {
          self.setState({ errorMessage: error.response.data, isLoading: false });
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        <div className="grid">
          <header className="grid__col--12 panel--padded--centered" role="banner">
            <a className="site-logo left" href="https://www.informationmapping.com/">
              <b className="srt">Information Mapping Logo</b>
            </a>
            <h1 className="headline-primary--grouped right"><a className="site-logo-trial" href="/">FS Pro Console</a></h1>
          </header>
        </div>
        <div className="grid">
          <div className="centered grid__col--8">
            <h2 className="headline-secondary--centered">Welcome to the FS Pro Console, you can manage, update and renew your FS Pro Licenses here.</h2>
          </div>
        </div>
        <div className="grid padded">
          <div id="reset-password" className="centered grid__col--5 panel__inverse--padded">
            {(this.state.errorMessage !== "") ? <div className="ui negative message">
              {this.state.errorMessage}
            </div> : ''}
            <h1 className="headline-primary--centered--inverse">Reset Password</h1>
            <p className="panel__text--center">Reset your password!</p>
            <form className="form-reset-password" onSubmit={this.handleResetPasswordSubmit} >
              <div className="form__group">
                <label className="form__label--hidden" htmlFor="new-password">New Password:</label>

                <Popup
                  trigger={<input
                    name="new-password"
                    className="form__input"
                    type="password"
                    placeholder="New Password"
                    value={this.state.newPassword}
                    onChange={this.handleNewPasswordChange}
                    required
                  />}
                  open={this.state.passwordHelper}
                  content={this.state.validations !== null ? this.state.validations.map((x, i) => (<div className="align left" key={i}>
                    {x.status ? <i className="check circle icon teal"></i> : <i className="minus circle icon red"></i>}
                    {x.message} <br /></div>)) : null}
                  header='Password Policy'
                  position='right center'
                  size='small'
                />
                {this.state.validations.filter(x => !x.status).length === 0 ?
                  <div>
                    <label className="form__label--hidden" htmlFor="confirm-password">Confirm Password:</label>
                    <input
                      autoComplete="off"
                      className="form__input"
                      type="password"
                      placeholder="Confirm Password"
                      value={this.state.confirmPassword}
                      onChange={this.handleConfirmPasswordChange}
                      required
                    />
                  </div> : null}

                <input className="ui button blue fluid huge" type="submit" value="Reset" disabled={this.state.isLoading} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
      showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
      hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(ResetPassword));