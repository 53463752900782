const AuthenticationInformation = {
  _instance: null,
  get instance() {
    if (!this._instance) {
      this._instance = {
        getCurrentUser() {
          return 'singletonMethod';
        },

        _isUserAuthenticated: false,
        _userInformation: null,
        _needVerification: true,
        _firstTry:true,

        get userInformation() {
          return this._userInformation;
        },

        get isUserAuthenticated() {
          return this._isUserAuthenticated;
        },

        get needVerification() {
          return this._needVerification;
        },

        get firstTry() {
          const ft = this._firstTry;
          this._firstTry = false;
          return ft;
        },

        set userInformation(userInformation) {
          this._userInformation = userInformation;
          if (!!userInformation) {
            this._isUserAuthenticated = true;
            this._needVerification = userInformation.needVerification;
            this._useSms = userInformation.sms;
          }
          else {

            this._isUserAuthenticated = this._needVerification = this._useSms = false;
          }
        }

      };
    }
    return this._instance;
  }
};

export default AuthenticationInformation;