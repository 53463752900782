import React, { Component } from 'react';

import { Grid, Input } from 'semantic-ui-react';

import matchSorter from 'match-sorter';

import _ from 'underscore';

import IMComponent from '../Components/IMComponent';

export default class ProductsGrid extends IMComponent {

    constructor(props) {
        super(props, {});
    }

    renderHeader = () => {
        const { queryProductName } = this.state;
        return [
            <Grid.Row key="header">
                <Grid.Column width={1} className="panel__text--title"></Grid.Column>
                <Grid.Column width={7} className="panel__text--title">PRODUCT NAME</Grid.Column>
                <Grid.Column width={2} className="panel__text--title"></Grid.Column>
            </Grid.Row>,
            <Grid.Row key="filter">
                <Grid.Column width={1} ></Grid.Column>
                <Grid.Column width={7} >
                    <Input
                        name="queryProductName"
                        type="text"
                        className="form-input"
                        fluid
                        value={queryProductName}
                        onChange={this.onPropertyChange} />
                </Grid.Column>
                <Grid.Column width={2} className="middle aligned" >
                    <a className="ui tag label blue">Filter</a>
                </Grid.Column>
            </Grid.Row>];
    }

    renderData = (data) => {
        return data.map((item, index) =>
            <Grid.Row key={index}>
                <Grid.Column width={1} key={index + "a"}>
                </Grid.Column>
                <Grid.Column width={7} key={index + "c"}>
                    <p>{item.name}</p>
                </Grid.Column>
                <Grid.Column width={2} key={index + "e"}>
                    
        <button type="button" className='ui button primary btn'
                        id={item.productId}
                        onClick={this.props.showProductEdit}
                    > <i className="fas fa-pencil-alt"></i> Edit</button>
        <button type="button" className='ui button red btn'
                        id={item.productId}
                        onClick={(r) => this.props.deleteProduct(r, item.name)}
                    > <i className="fas fa-trash-alt"></i>  Delete</button>
         </Grid.Column>
            </Grid.Row>);
    }

    onFilterChanged = () => {
        const { queryProductName } = this.state;
        let resultData = this.props.data;
        var format = /[!@._#$%^&*()+\-=\[\]{};':"\\|,<>\/?]+/;
        if (format.test(queryProductName)) {            
            alert("Please specify valid characters for product names");
            return resultData;
        }
        
        if (queryProductName)
            resultData = matchSorter(resultData, queryProductName, { keys: [x => x.name] });
        return _.sortBy(resultData, x => x.name.trim());
    }

    render() {
        const data = this.onFilterChanged();
        return <Grid>{[this.renderHeader(), this.renderData(data)]}</Grid>;
    }
}
