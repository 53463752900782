import React from 'react';
function CenteredMidLayout({ children: Children }) {
    return (
        <div className="grid">
            <div id="getemail" className="centered grid__col--5">
                {Children}
            </div>
        </div>
    )
}
export default CenteredMidLayout;