import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom';

import PropsRoute from '../Components/PropsRoute';

import List from './List';

export default class Index extends Component {
    render() {
        return (
            <Switch>
                <PropsRoute exact path="/license-usage/:licenseId" Component={List} isAdmin={this.props.isAdmin} />
            </Switch>
        )
    }
}
