import Axios from 'axios';

import { convertToForm } from '../Utilities/Utils';

import { request } from './http';

const itemPrefix = 'Authenticate';

export const authenticateZendDesk = function (data) {
    return request({
        url: `${itemPrefix}/Zendesk`,
        method: 'POST',
        data:convertToForm(data)
    });
}