import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthenticationInformation from '../Utilities/AuthenticationInformation';

import PrivateLayout from './PrivateLayout';

export default class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state={

    }
  }
  render() {
    const { ...rest } = this.props;
    return (<Route
      {...rest}
      render={props => {
        const newProps = { ...props, ...rest }
        return (
          AuthenticationInformation.instance.isUserAuthenticated ?
            (AuthenticationInformation.instance.needVerification ?
              <Redirect path="*" to="/verification-code" /> :
              <PrivateLayout {...newProps} />)
            :(
              AuthenticationInformation.instance.firstTry?
            <Redirect path="*" to="/Login" />:
            <Redirect path="*" to="/not-authenticated" />
            )
        );
      }
      }
    />)
  }
}
