
export const actions = {
    setCurrentUser: "SET_CURRENTUSER",
    showLoading: "SHOW_LOADING",
    hideLoading: "HIDE_LOADING",
    notification: "NOTIFICATION",
}

export const applicationActionCreator = {
    setCurrentUser: function (payload) {

        return {
            type: actions.setCurrentUser,
            payload
        };
    },
    showLoading: function (caller) {

        return {
            type: actions.showLoading,
            payload: caller
        };
    },
    hideLoading: function (caller) {

        return {
            type: actions.hideLoading,
            payload: caller
        };
    },
    notification: {
        error: function (error) {
            return {
                type: actions.notification,
                payload: {
                    error
                }
            }
        },
        success: function (success) {
            return {
                type: actions.notification,
                payload: {
                    success
                }

            }

        }
    }
}

export default applicationActionCreator

